import { useEffect, useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import { AuthContext } from './contexts/JWTContext';
import useAuth from './hooks/useAuth';
// guards
import AuthGuard from './guards/AuthGuard';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import Spinner from './components/Spinner';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import './styles/Scrollbar.css';
import './styles/index.css';
import { getUserSession, setUserSession } from './utilities/utilities';
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { state, login, logout } = useContext(AuthContext);
  console.log(window.location);

  if (window.location.pathname.includes('create-password') && getUserSession()) {
    localStorage.clear();
    // debugger; // eslint-disable-line no-debugger
    logout();
    // if (isAuthenticated) {
    //   localStorage.clear();
    //   debugger; // eslint-disable-line no-debugger
    //   logout();
    // } else {
    //   debugger; // eslint-disable-line no-debugger
    // }
  }
  useEffect(() => {
    if ((!getUserSession() && isAuthenticated) || moment().diff(moment(getUserSession()), 'minutes') > 240) {
      localStorage.clear();
      logout();
      Navigate('/');
    } else {
      setUserSession(moment());
    }
  }, [pathname]);
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              {/* {pathname.includes('/dashboard') ? <Settings /> : ''} */}
              <ScrollToTop />
              <GoogleAnalytics />
              {/* {isInitialized ? <Router /> : <LoadingScreen />} */}
              {isInitialized ? <Router /> : <Spinner />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

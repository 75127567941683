// Create our number formatter.
import { getRoleId } from '../utilities/utilities';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
});

function deFormat(value) {
  if (typeof value === 'string') {
    value = Number(value.replace(/[^0-9.-]+/g, ''));
  }

  return value;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const billion = 1.0e9;
const million = 1.0e6;
const thousand = 1.0e3;

function numberformatter(value, formatK) {
  if (Number.isNaN(Number(value))) return value;
  if (Number(value) >= billion) {
    value = `${(Number(value) / billion).toFixed(2)}B`;
  } else if (Number(value) >= million) {
    value = `${(Number(value) / million).toFixed(2)}M`;
  } else if (formatK && Number(value) >= thousand) {
    value = `${(Number(value) / thousand).toFixed(2)}K`;
  } else {
    value = numberWithCommas(Number(value));
  }

  return value;
}

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const formattedDate = [year, month, day].join('-');

  return formattedDate === '01-01-1970' || formattedDate === '12-31-1969' ? '' : formattedDate;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function setCookie(name, value, minutes) {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function getFileName(response) {
  let filename = '';
  if (response?.headers && Object.keys(response.headers).length > 0) {
    filename = response.headers
      .get('content-disposition')
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();
  }

  return filename;
}

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

function getParams(params) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(params);
}

function get12Hour(time) {
  if (time) {
    let string;
    let hour24 = time.split(':')[0];
    const minutes = time.split(':')[1];
    if (hour24 > 12) {
      hour24 -= 12;
      string = 'PM';
    } else if (hour24 < 12) {
      string = 'AM';
    } else {
      string = 'PM';
    }
    return `${hour24}:${minutes} ${string}`;
  }
}

function formatTimeDuration(time) {
  let toHours;
  let toMinutes;
  if (time) {
    const hours = Number(time) / 60;
    if (hours % 1 !== 0) {
      [toHours, toMinutes] = hours.toString().split('.');
      toMinutes *= 60;
    } else {
      toMinutes = '00';
    }
  }
  return time ? `${toHours}:${toMinutes.toString().slice(0, -1)}` : '00:00';
}

function TotalSpentHrs(array) {
  let totalmins = 0;
  array?.map((x) => (totalmins += x.total_hours));
  // const nd = new Date(totalmins * 60 * 1000).toISOString().substr(11, 5);
  const nd = getTimeFromMins(totalmins);
  return nd;
}

function getTimeFromMins(mins) {
  const hours = mins / 60;
  let rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  if (rhours.toString().length !== 2) {
    rhours = `0${rhours}`;
  }
  if (rminutes.toString().length !== 2) {
    rminutes = `0${rminutes}`;
  }
  return `${rhours}:${rminutes}`;
  // console.log(new Date(mins * 60 * 1000).toISOString().substr(11, 5));
  // return new Date(mins * 60 * 1000).toISOString().substr(11, 5);
}

const minToHrs = (min) => {
  const hrs = min / 60;
  if (hrs % 1 !== 0) {
    const toHrs = hrs.toString().split('.')[0];
    // const toMns = (Number(hrs.toString().split('.')[1]) / 100).toFixed(2) * 60;
    const mnsString = `0.${hrs.toString().split('.')[1]}`;
    const toMns = Math.round(Number(mnsString) * 60);
    if (Number(toHrs) === 0) {
      return `${toMns}m`;
    }
    if (Number(toMns) === 0) {
      return `${toHrs}h`;
    }
    return `${toHrs}h ${toMns}m`;
  }
  return `${hrs}h`;
};

const minToHHmm = (totalMins) => {
  const minutes = totalMins % 60;
  const hours = (totalMins - minutes) / 60;
  return `${hours}:${minutes}`;
};

const timeConvert = (num) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  return `${hours}:${minutes}`;
};

const getTimefromMS = (milliseconds) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  return `${hours}:${minutes}`;
};

const getOwner = () => {
  let toggle;
  if (Number(getRoleId()) === 3) {
    toggle = true;
  } else {
    toggle = false;
  }
  return toggle;
};

const getAdmin = () => {
  let toggle;
  if (Number(getRoleId()) === 1) {
    toggle = true;
  } else {
    toggle = false;
  }
  return toggle;
};

const getHR = () => {
  let toggle;
  if (Number(getRoleId()) === 8) {
    toggle = true;
  } else {
    toggle = false;
  }
  return toggle;
};

export {
  formatter,
  deFormat,
  get12Hour,
  formatTimeDuration,
  numberformatter,
  setCookie,
  getCookie,
  eraseCookie,
  formatDate,
  getFileName,
  bytesToSize,
  getTimeFromMins,
  capitalizeFirstLetter,
  TotalSpentHrs,
  minToHrs,
  minToHHmm,
  timeConvert,
  getTimefromMS,
  getParams,
  getOwner,
  getAdmin,
  getHR
};

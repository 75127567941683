import CryptoJS from 'crypto-js';

export const getUserEmail = () => {
  if (localStorage.getItem('sd_key_1')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_1'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserEmail = (email = '') => {
  localStorage.setItem(
    'sd_key_1',
    CryptoJS.AES.encrypt(JSON.stringify(email), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getRoleId = () => {
  if (localStorage.getItem('sd_key_2')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_2'), process.env.REACT_APP_ENCRYPTION_KEY);
    return Number(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  }
  return null;
};

export const setRoleId = (id = null) => {
  localStorage.setItem(
    'sd_key_2',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserId = () => {
  if (localStorage.getItem('sd_key_3')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_3'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};
export const getCompanyName = () => {
  if (localStorage.getItem('sd_key_4')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_4'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserId = (id = null) => {
  localStorage.setItem(
    'sd_key_3',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const setCompanyName = (companyName = '') => {
  localStorage.setItem(
    'sd_key_4',
    CryptoJS.AES.encrypt(JSON.stringify(companyName), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getIsOwner = () => {
  if (localStorage.getItem('sd_key_5')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_5'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setIsOwner = (owner = 0) => {
  localStorage.setItem(
    'sd_key_5',
    CryptoJS.AES.encrypt(JSON.stringify(owner), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserState = () => {
  if (localStorage.getItem('sd_key_6')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_6'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserState = (state = null) => {
  localStorage.setItem(
    'sd_key_6',
    CryptoJS.AES.encrypt(JSON.stringify(state), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getUserName = () => {
  if (localStorage.getItem('sd_key_7')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_7'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserName = (sd_key_7 = '') => {
  localStorage.setItem(
    'sd_key_7',
    CryptoJS.AES.encrypt(JSON.stringify(sd_key_7), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getUserSession = () => {
  if (localStorage.getItem('sd_key_8')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_8'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserSession = (sd_key_8 = null) => {
  localStorage.setItem(
    'sd_key_8',
    CryptoJS.AES.encrypt(JSON.stringify(sd_key_8), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getGlobalProjectId = () => {
  if (localStorage.getItem('sd_key_9')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_9'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setGlobalProjectId = (id = null) => {
  localStorage.setItem(
    'sd_key_9',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUser = () => {
  if (localStorage.getItem('sd_key_10')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_10'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUser = (user = null) => {
  localStorage.setItem(
    'sd_key_10',
    CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getCompanyId = () => {
  if (localStorage.getItem('sd_key_11')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_11'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setCompanyId = (id = null) => {
  localStorage.setItem(
    'sd_key_11',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserCountry = () => {
  if (localStorage.getItem('sd_key_12')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_12'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserCountry = (country = null) => {
  localStorage.setItem(
    'sd_key_12',
    CryptoJS.AES.encrypt(JSON.stringify(country), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserCity = () => {
  if (localStorage.getItem('sd_key_13')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_13'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserCity = (city = null) => {
  localStorage.setItem(
    'sd_key_13',
    CryptoJS.AES.encrypt(JSON.stringify(city), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getCountryCodeIso = () => {
  if (localStorage.getItem('sd_key_14')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_14'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setCountryCodeIso = (id = null) => {
  localStorage.setItem(
    'sd_key_14',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getProjectExist = () => {
  if (localStorage.getItem('sd_key_15')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_15'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setProjectExist = (id = null) => {
  localStorage.setItem(
    'sd_key_15',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getAccessToken = () => {
  if (localStorage.getItem('sd_key_16')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setAccessToken = (token = null) => {
  localStorage.setItem(
    'sd_key_16',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getJwtToken = () => {
  if (localStorage.getItem('sd_key_17')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setJwtToken = (token = null) => {
  localStorage.setItem(
    'sd_key_17',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

import { Suspense, lazy, useState, useEffect } from 'react';
import { Navigate, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import CompanyGuard from '../guards/CompanyGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
// import LoadingScreen from '../components/LoadingScreen';
import Spinner from '../components/Spinner';
// contexts
import { LogFilterContext } from '../contexts/LogFilterContext';
import { getCompanyId, getRoleId } from '../utilities/utilities';

const rolesArr = ['Admin', 'HR', 'Owner', 'Project Manager'];

// ----------------------------------------------------------------------
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const [resource, config] = args;

  // Request interceptor
  // resource = 'https://jsonplaceholder.typicode.com/todos/2';

  const response = await originalFetch(resource, config);

  // Response interceptor
  if (response.status === 401) {
    // store.dispatch(logoutUser());
  }

  return response;
};
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        // <LoadingScreen
        <Spinner
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        // { path: '*', element: <Navigate to="/login-unprotected" /> },
        {
          path: 'forget-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          )
        },
        {
          path: 'invitecompany',
          element: <InviteCompanyPage />
        },
        {
          path: 'confirm-password',
          element: (
            <GuestGuard>
              <ConfirmPassword />
            </GuestGuard>
          )
        },
        {
          path: 'create-password',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: '/register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    },

    // Company Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <CompanyGuard>
            <DashboardLayout />
          </CompanyGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'attendance',
          children: [
            {
              path: '/',
              element: (
                <Navigate to={Number(getRoleId()) === 10 ? '/admin/charts' : '/dashboard/attendance/view'} replace />
              )
            },
            // { path: '/dashboard', element: <Empty /> },
            { path: '/view', element: <Attendance /> },
            { path: '/timing', element: <Timing /> },
            {
              path: '/log',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <Log />
                </RoleBasedGuard>
              )
            }
            // Number(getRoleId()) === 1 ||
            // Number(getRoleId()) === 3 ||
            // Number(getRoleId()) === 8
            //   ? {
            //       path: '/log',
            //       element: (
            //         <RoleBasedGuard accessibleRoles={rolesArr}>
            //           <Log />{' '}
            //         </RoleBasedGuard>
            //       )
            //     }
            //   : {}
          ]
        },
        {
          path: 'leaves',
          children: [
            { path: '/', element: <Navigate to="/dashboard/leaves/all" replace /> },
            { path: '/all', element: <Leaves /> },
            { path: '/my-leaves', element: <UserLeaveRecords /> },
            { path: '/management', element: <LeaveRecords /> },
            {
              path: '/pending-approvals',
              element: (
                // localStorage.getItem('showApproval') ?
                <PendingApproval />
              )
              //  : <NotFound />
            },
            { path: '/holidays', element: <Holidays /> },
            { path: '/schedule', element: <LeaveSchedule /> },
            {
              path: '/transactions',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <TransactionReport />
                </RoleBasedGuard>
              )
            },
            {
              path: '/entitlement',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <EntitlementReport />
                </RoleBasedGuard>
              )
            },

            {
              path: '/types',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <LeaveTypes />
                </RoleBasedGuard>
              )
            },
            {
              path: '/workdays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <WorkDays />
                </RoleBasedGuard>
              )
            },
            {
              path: '/earning-policy',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <EarningPolicy />
                </RoleBasedGuard>
              )
            },
            {
              path: '/approval-workflow',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ApprovalWorkflow />
                </RoleBasedGuard>
              )
            }
            // { path: '/records', element: <LeaveRecords /> },
            // { path: '/review', element: <Empty /> },
            // { path: '/transactions', element: <Empty /> },
            // { path: '/entitlement', element: <Empty /> },
          ]
        },
        {
          path: 'payroll',
          children: [
            { path: '/', element: <Navigate to="/dashboard/payroll/salary-adjustment" replace /> },
            // { path: '/all', element: <SalaryAdjustment /> },
            { path: '/salary-adjustment', element: <SalaryAdjustment /> }
          ]
        },
        {
          path: 'projects',
          children: [
            { path: '/', element: <Navigate to="/dashboard/projects/all" replace /> },
            { path: '/all', element: <Projects /> },
            {
              path: '/new',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CreateProject />
                </RoleBasedGuard>
              )
            },
            { path: '/view', element: <CreateProject /> },
            {
              path: '/edit',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CreateProject />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'tasks',
          children: [
            {
              path: '/',
              element: <Navigate to={`/dashboard/tasks${localStorage.getItem('TaskView') || '/list'}`} replace />
            },
            // { path: '/all', element: <Navigate to="/dashboard/tasks/list" replace /> },
            { path: '/list', element: <Tasks /> },
            { path: '/board', element: <Kanban /> },
            { path: '/new', element: <CreateTask /> },
            { path: '/view', element: <CreateTask /> },
            {
              path: '/edit',
              element: <CreateTask />
            }
          ]
        },
        {
          path: 'timesheet',
          children: [
            { path: '/', element: <Navigate to="/dashboard/log/weekly" replace /> },
            { path: '/weekly', element: <Weekly /> },
            {
              path: '/list',
              element: <ListView />
            },
            {
              path: '/missing-timesheet',
              element: <MissingList />
            },
            { path: '/calendar', element: <Calendar /> }
          ]
        },
        {
          path: 'team',
          children: [
            { path: '/', element: <Navigate to="/dashboard/team/all" replace /> },
            { path: '/all', element: <TeamList /> },
            {
              path: '/new',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <TeamCreate />
                </RoleBasedGuard>
              )
            },
            // Number(getRoleId()) === 1 ||
            // Number(getRoleId()) === 3 ||
            // Number(getRoleId()) === 8
            //   ? {
            //       path: '/new',
            //       element: (
            //         <RoleBasedGuard accessibleRoles={rolesArr}>
            //           <TeamCreate />
            //         </RoleBasedGuard>
            //       )
            //     }
            //   : {},
            {
              path: '/edit',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <UserEdit key={document.location.href} />
                </RoleBasedGuard>
              )
            },
            {
              path: '/view',
              element: <UserEdit key={document.location.href} />
            }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/view" replace /> },
            { path: '/view', element: <UserEdit key={document.location.href} /> },
            { path: '/edit', element: <UserEdit key={document.location.href} /> }
          ]
        },
        {
          path: 'account',
          children: [
            { path: '/', element: <Navigate to="/account/change-password" replace /> },
            { path: '/change-password', element: <ChangePassword /> }
          ]
        }
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // Super Admin Routes
        { path: '/', element: <Navigate to="/admin/user-management" replace /> },
        { path: '/charts', element: <AdminDashboard /> },
        { path: '/email-configuration', element: <EmailConfig /> },
        { path: '/email-templates', element: <EmailTemp /> },
        { path: '/role-management', element: <RoleManagement /> },
        { path: '/permission-management', element: <PermissionManagement /> },
        { path: '/user-management', element: <UserManagement /> },
        { path: '/company-management', element: <CompanyManagement /> },
        { path: '/owner-management', element: <OwnerManagement /> },
        { path: '/general-settings', element: <GeneralSettings /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: (
            <Navigate
              to={
                Number(getRoleId()) === 10
                  ? '/admin/charts'
                  : getCompanyId()
                  ? '/dashboard/attendance/view'
                  : '/dashboard/company/edit'
              }
              replace
            />
          )
        },
        {
          path: 'company',
          children: [
            { path: '/view', element: <CompanyView /> },
            { path: '/edit', element: <CompanyEdit /> },
            { path: '/subcompany/:id', element: <SubCompanyView /> },
            { path: '/subcompany', element: <SubCompanyView /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'email-sent', element: <EmailSent /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'landing-page',
          element: (
            <RoleBasedGuard accessibleRoles={rolesArr}>
              <LandingPage />
            </RoleBasedGuard>
          )
        },
        {
          path: 'about-us',
          element: (
            <RoleBasedGuard accessibleRoles={rolesArr}>
              <About />
            </RoleBasedGuard>
          )
        },
        {
          path: 'contact-us',
          element: (
            <RoleBasedGuard accessibleRoles={rolesArr}>
              <Contact />
            </RoleBasedGuard>
          )
        },
        {
          path: 'faqs',
          element: (
            <RoleBasedGuard accessibleRoles={rolesArr}>
              <Faqs />{' '}
            </RoleBasedGuard>
          )
        },
        {
          path: 'components',
          children: [
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            { path: 'data-grid', element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            // { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> },
            { path: 'form-validation', element: <FormValidation /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ConfirmPassword = Loadable(lazy(() => import('../pages/authentication/ConfirmPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/account/AccountPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Empty = Loadable(lazy(() => import('../pages/dashboard/DashboardEmpty')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/DashboardApp')));
const Attendance = Loadable(lazy(() => import('../pages/dashboard/Attendance')));
const Leaves = Loadable(lazy(() => import('../pages/dashboard/leaves/Leaves')));
const LeaveRecords = Loadable(lazy(() => import('../pages/dashboard/leaves/EntitlmentRecords')));
const EntitlementReport = Loadable(lazy(() => import('../pages/dashboard/leaves/EntitlementReport')));
const TransactionReport = Loadable(lazy(() => import('../pages/dashboard/leaves/TransactionReport')));
const EarningPolicy = Loadable(lazy(() => import('../pages/dashboard/leaves/EarningPolicy')));
const LeaveTypes = Loadable(lazy(() => import('../pages/dashboard/leaves/LeaveTypes')));
const WorkDays = Loadable(lazy(() => import('../pages/dashboard/leaves/WorkDays')));
const PendingApproval = Loadable(lazy(() => import('../pages/dashboard/leaves/PendingApprovals')));
const Holidays = Loadable(lazy(() => import('../pages/dashboard/leaves/Holidays')));
const ApprovalWorkflow = Loadable(lazy(() => import('../pages/dashboard/leaves/ApprovalWorkflow')));
const LeaveSchedule = Loadable(lazy(() => import('../pages/dashboard/leaves/LeaveSchedule')));
const UserLeaveRecords = Loadable(lazy(() => import('../pages/dashboard/leaves/UserLeaveRecords')));
const SalaryAdjustment = Loadable(lazy(() => import('../pages/dashboard/payroll/SalaryAdjustment')));

const Timing = Loadable(lazy(() => import('../pages/dashboard/attendance/Timing')));
const Log = Loadable(lazy(() => import('../pages/dashboard/attendance/AttendanceLog')));
const CompanyView = Loadable(lazy(() => import('../pages/dashboard/CompanyView')));
const CompanyEdit = Loadable(lazy(() => import('../pages/dashboard/CompanyEdit')));
const SubCompanyView = Loadable(lazy(() => import('../pages/dashboard/SubCompanyView')));
const Projects = Loadable(lazy(() => import('../pages/dashboard/Projects')));
const Tasks = Loadable(lazy(() => import('../pages/dashboard/Tasks')));
const Weekly = Loadable(lazy(() => import('../pages/dashboard/Timesheet')));
const ListView = Loadable(lazy(() => import('../pages/dashboard/ListView')));
const MissingList = Loadable(lazy(() => import('../pages/dashboard/MissingList')));
const TeamList = Loadable(lazy(() => import('../pages/dashboard/TeamList')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/TeamCreate')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const CreateProject = Loadable(lazy(() => import('../pages/dashboard/CreateProject')));
const CreateTask = Loadable(lazy(() => import('../pages/dashboard/CreateTask')));
// Super Admin
const AdminDashboard = Loadable(lazy(() => import('../pages/superAdmin/DashboardApp')));
const EmailConfig = Loadable(lazy(() => import('../pages/superAdmin/EmailConfig')));
const EmailTemp = Loadable(lazy(() => import('../pages/superAdmin/EmailTemplates')));
const RoleManagement = Loadable(lazy(() => import('../pages/superAdmin/RoleManagement')));
const PermissionManagement = Loadable(lazy(() => import('../pages/superAdmin/PermissionManagement')));
const UserManagement = Loadable(lazy(() => import('../pages/superAdmin/UserManagement')));
const CompanyManagement = Loadable(lazy(() => import('../pages/superAdmin/CompanyManagement')));
const OwnerManagement = Loadable(lazy(() => import('../pages/superAdmin/OwnerManagement')));
const GeneralSettings = Loadable(lazy(() => import('../pages/superAdmin/GeneralSettings')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const EmailSent = Loadable(lazy(() => import('../pages/EmailSent')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColors')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
//
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const FormValidation = Loadable(lazy(() => import('../pages/components-overview/extra/form-validation')));
const InviteCompanyPage = Loadable(lazy(() => import('../pages/account/InviteCompanyPage')));

export const calendarSchema = {
  title: 'Title is required.',
  endDate: 'End date must be later than start date.'
};

export const projectSchema = {
  name: 'Project name is required.',
  maximum: 'Maximum.',
  positive: 'Must be positive.',
  taskType: 'Task type is required.',
  shortName: 'Short name is required.'
};

export const taskSchema = {
  projName: 'Project name is required.',
  trim: 'Spaces are not allowed.',
  maximum: 'Maximum.',
  title: 'Task title is required.',
  type: 'Task type is required.',
  priority: 'Priority is required.',
  startDate: 'Start date is required.',
  dueDate: 'Due date is required.',
  positive: 'Must be positive.',
  maxHours: 'Maximum 8 hours.'
};

export const logSchema = {
  endDate: 'Time spent date is required.'
};

export const userSchema = {
  name: 'Name is required.',
  firstName: 'First name is required.',
  firstNameValid: 'First name is invalid.',
  lastName: 'Last name is required.',
  lastNameValid: 'Last name is invalid.',
  maximum: 'Too Long!.',
  trim: 'Spaces are not allowed.',
  alphabets: 'Only alphabets are allowed for this field.',
  email: 'Email is required.',
  emailValid: 'Email address is invalid.',
  timeIn: 'Time in cannot be empty.',
  timeOut: 'Time out cannot be empty.',
  phone: 'Phone number should be at least 10 digits.',
  address: 'Address is required.'
};

export const cardSchema = {
  name: 'Name is required.',
  number: 'Card number is required.',
  expiry: 'Card expiry date is required.',
  cvv: 'Cvv number is required.'
};

export const authSchema = {
  oldPassword: 'Old password is required.',
  newPassword: 'New password is required.',
  confirmPassword: 'Confirm password is required.',
  passwordFormat:
    'Your password must contains at least 8 characters, one lowercase letter, one uppercase letter, one numeric digit and one special character.',
  matchPassword: 'Passwords must match.',
  email: 'Email is required.',
  emailValid: 'Email address is invalid.',
  password: 'Password is required.',
  trim: 'Spaces not allowed.'
};

export const companySchema = {
  name: 'Company name is required.',
  owner: "Owner's name is required.",
  city: 'City is required.',
  country: 'Country is required.',
  description: 'Description is required.',
  descriptionMin: 'Description should be at least 50 characters.',
  descriptionMax: 'Description cannot be longer than 1000 characters.',
  industry: 'Industry is required.',
  address: 'Company address is required.',
  employeeCount: 'Number of employees is required.',
  url: 'Website url is required.',
  urlValid: 'URL is not valid.',
  email: 'Company email is required'
};

export const numberSchema = {
  maxDescLimit: 1000,
  minDescLimit: 1,
  maxNameLimit: 50,
  maxPhoneLimit: 14,
  maxTitle: 240,
  maxProjectTitle: 50,
  maxEstimatedHours: 8,
  minCharLimit: 8,
  maxEstimation: 99999
};

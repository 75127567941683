import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------
MyAvatar.propTypes = {
  about: PropTypes.object
};
export default function MyAvatar({ profile, ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={profile?.user_profile_img}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName)?.color}
      {...other}
    >
      {createAvatar(user?.displayName)?.name}
    </MAvatar>
  );
}

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import CountryFlag from './CountryFlag';
import { getProjectbyUser, getUserProfileInfo } from '../../server/server';
import { getGlobalProjectId } from '../../utilities/utilities';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 220;
const COLLAPSE_WIDTH = 100;

const APPBAR_MOBILE = 35;
const APPBAR_DESKTOP = 35;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  zIndex: 100,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('sm')]: {
    minHeight: APPBAR_DESKTOP
    // padding: theme.spacing(0, 1.25)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (pathname.includes('/tasks')) {
  //     recall();
  //   }
  // }, []);
  // const [search, setSearch] = useState('');
  const recall = () => {
    getProjectbyUser().then((res) => {
      if (!res?.Message && res?.length > 0) {
        const array = [];
        res?.map((x) => {
          if (x?.Projects?.length > 0) {
            array.push(x?.Projects[0]);
          }
          return true;
        });
        // setProjects(array);
        const data = array?.filter((x) => x?.proj_auto_id === Number(getGlobalProjectId()));
        setProject(data[0]);
      }
    });
  };
  const { isCollapse } = useCollapseDrawer();

  const onSearchSubmit = (query) => {
    console.log(query);
  };

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'white' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar onSearch={onSearchSubmit} /> */}
        <Typography noWrap>{project?.length !== 0 ? project?.proj_name : null}</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 2 }}>
          {/* <CountryFlag /> */}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  Stack,
  Button,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  Select,
  InputLabel,
  MenuItem,
  Menu,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import MuiFormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import CollapseButton from './CollapseButton';
//
import sidebarConfig from './SidebarConfig';
import { getLeaveForApprovalsCheck, getProjectbyUser } from '../../server/server';
import {
  getGlobalProjectId,
  setGlobalProjectId,
  getRoleId,
  getUserId,
  setProjectExist
} from '../../utilities/utilities';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 220;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0.5, 1.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[500_12]
// }));

const FormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
      lineHeight: 1,
      marginTop: -3
    },
    '& .MuiInputBase-input': {
      paddingTop: 9,
      paddingBottom: 10
    }
  }
})(MuiFormControl);

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [data, setData] = useState(sidebarConfig());
  const [project, setProject] = useState([]);
  const [showApproval, setShowApproval] = useState(0);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedValue, setSelectedValue] = useState(Number(getGlobalProjectId()));
  // const open = Boolean(anchorEl);

  // const handleClickListItem = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuItemClick = (event, index) => {
  //   setSelectedIndex(index);
  //   setAnchorEl(null);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const changeSelect = (e) => {
    setSelectedValue(e.target.value);
    setGlobalProjectId(e.target.value);
    window.location.reload();
  };

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    getLeaveForApprovalsCheck().then((item) => {
      if (
        item.leaevAllData.filter(
          (x) =>
            ((x.leave_flag === 0 || x.leave_flag === 1) &&
              x?.manager[0]?.recruitment_manager === Number(getUserId())) ||
            x?.manager[0]?.functional_manager === Number(getUserId())
        ).length
      ) {
        setShowApproval(
          item.leaevAllData.filter(
            (x) =>
              ((x.leave_flag === 0 || x.leave_flag === 1) &&
                x?.manager[0]?.recruitment_manager === Number(getUserId())) ||
              x?.manager[0]?.functional_manager === Number(getUserId())
          ).length
        );
        localStorage.setItem(
          'showApproval',
          item.leaevAllData.filter(
            (x) =>
              ((x.leave_flag === 0 || x.leave_flag === 1) &&
                x?.manager[0]?.recruitment_manager === Number(getUserId())) ||
              x?.manager[0]?.functional_manager === Number(getUserId())
          ).length
        );
      } else {
        setShowApproval(0);
        localStorage.setItem('showApproval', '');
      }
    });
    setSelectedValue(getGlobalProjectId());
    // if (pathname.includes('/company') || pathname.includes('/tasks') || pathname.includes('/dashboard/app')) {
    getProjectbyUser().then((res) => {
      if (!res?.Message && res?.length > 0) {
        const array = [];
        res?.map((x) => {
          if (x.Projects.length > 0) {
            array.push(x.Projects[0]);
          }
          return true;
        });
        setProject(array);
        setProjectExist(1);
      } else {
        setProject([]);
        setProjectExist(0);
      }
    });
    // }
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    setData(sidebarConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderButton = () => {
    const newProject = pathname.includes('/projects/all');
    const newTaskList = pathname.includes('/tasks/list');
    const newTaskBoard = pathname.includes('/tasks/board');
    const newTask = pathname.includes('/tasks');
    const addTime = pathname.includes('/log/view');
    const newMember = pathname.includes('/team/all') && [1, 3, 8].includes(Number(getRoleId()));

    // const editProfile = pathname.includes('/user/profile');
    const path = () => {
      if (newProject) return PATH_DASHBOARD.projects.new;
      if (newTaskList || newTaskBoard) return PATH_DASHBOARD.tasks.new;
      if (addTime) return PATH_DASHBOARD.timesheet.add;
      if (newMember) return PATH_DASHBOARD.team.new;
      // if (editProfile) return PATH_DASHBOARD.user.edit;
      return '';
    };
    if (newProject || newTaskList || newTaskBoard || addTime || newMember) {
      return (
        <Box mt={2}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            component={RouterLink}
            to={path()}
            startIcon={<Icon icon={plusFill} />}
          >
            {(() => {
              if (newProject) return 'New project';
              if (newTask) return 'New task';
              if (addTime) return 'Add time';
              if (newMember) return 'New member';
              // if (editProfile) return 'edit profile';
              return '';
            })()}
          </Button>
        </Box>
      );
    }
    return '';
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        sx={{
          px: 2.5,
          pt: 2,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <></>
        ) : (
          <>
            {/* <Link mt={2} underline="none" component={RouterLink} to={PATH_DASHBOARD.user.view}>
              <AccountStyle>
                <Box
                  sx={{ ml: 2, mt: 0.75, mb: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                  <Typography variant="body" sx={{ color: 'text.primary' }}>
                    {user?.displayName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {user?.role}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link> */}
            {(() => {
              if (project !== [] && project[0] !== undefined && project.length !== 0)
                return (
                  <>
                    {/* {pathname.includes('/tasks') ||
                    pathname.includes('/dashboard/app') ||
                    pathname.includes('/company')  */}
                    {pathname.includes('/') ? (
                      <Box mt={2.75}>
                        <FormControl fullWidth>
                          <InputLabel id="project-select-label">Project</InputLabel>
                          <Select
                            labelId="project-select-label"
                            id="project-select"
                            label="Project"
                            placeholder="Choose project"
                            onChange={changeSelect}
                            MenuProps={{ sx: { zIndex: 2001 } }}
                            value={selectedValue}
                            defaultValue={selectedValue}
                          >
                            {/* <MenuItem disabled selected>
                              Choose project
                            </MenuItem> */}
                            {/* <MenuItem value="">No project</MenuItem> */}
                            {project?.map((arr) => (
                              <MenuItem value={arr.proj_auto_id}>{arr.proj_name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}
                  </>
                );
              return null;
            })()}
            {renderButton()}
          </>
        )}
      </Stack>

      <NavSection showApproval={showApproval} navConfig={data} isShow={!isCollapse} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        zIndex: 2000,
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
// IconCollapse.propTypes = {
//   onToggleCollapse: PropTypes.func,
//   collapseClick: PropTypes.bool
// };

// function IconCollapse({ onToggleCollapse, collapseClick }) {
//   return (
//     <Tooltip title="Mini Menu">
//       <CardActionArea
//         onClick={onToggleCollapse}
//         sx={{
//           width: 18,
//           height: 18,
//           display: 'flex',
//           cursor: 'pointer',
//           borderRadius: '50%',
//           alignItems: 'center',
//           color: 'text.primary',
//           justifyContent: 'center',
//           border: 'solid 1px currentColor',
//           ...(collapseClick && {
//             borderWidth: 2
//           })
//         }}
//       >
//         <Box
//           sx={{
//             width: 8,
//             height: 8,
//             borderRadius: '50%',
//             bgcolor: 'currentColor',
//             transition: (theme) => theme.transitions.create('all'),
//             ...(collapseClick && {
//               width: 0,
//               height: 0
//             })
//           }}
//         />
//       </CardActionArea>
//     </Tooltip>
//   );
// }

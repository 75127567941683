import { getCookie, formatDate } from '../helpers';
import moment from 'moment';
import { id } from 'date-fns/locale';
import {
  getUserName,
  getRoleId,
  getUserState,
  setUserState,
  getUserId,
  getUser,
  getCompanyId,
  setUserCountry,
  getUserCity,
  setUserCity,
  setCountryCodeIso,
  getCountryCodeIso,
  getUserSession,
  getJwtToken
} from '../utilities/utilities';
//
const BASE_URL = 'https://manage.unikoop.nl:8096';
// const BASE_URL = 'https://Unikoop Manage.com:3001';
//
const jwtToken = () => {
  // return localStorage.getItem('jwtToken');
  return getJwtToken();
};

function parse(num) {
  return ('0' + (Math.floor(num) % 24)).slice(-2) + ':' + ((num % 1) * 60 + '0').slice(0, 2);
}

function isAuthentic() {
  let val = false;
  const authed = getCookie('authed');

  if (authed === 'true') {
    val = true;
  } else {
    window.location.href = '/login';
  }

  return val;
}

function getCommonResponse(res) {
  if (res && res.status) {
    return {
      status: res.status < 299,
      errors: res.status < 299 ? null : []
    };
  }
}

export const GetUserLocation = async () => {
  setUserCountry(null);
  setUserState(null);
  setUserCity(null);
  const response = await fetch(`https://ipfind.co/me?auth=dbe817fa-2c28-4e15-be56-40ad7baadcff`);
  const data = await response.json();
  if (response.status == 200) {
    setUserCountry(data.country);
    setUserState(data.region);
    setUserCity(data.city);
    setCountryCodeIso(data.country_code);
    return data;
  } else {
    setUserCountry(null);
    setUserState(null);
    setUserCity(null);
    return null;
  }
};

export const postLogin = async (email, password) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/login`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_username: email,
      user_password: password
    })
  });
  const data = await response.json();
  return data;
};

export const postSignup = async (ufn, uln, email) => {
  // if (!isAuthentic()) return;

  const bodyData = {
    user_first_name: ufn,
    user_last_name: uln,
    user_username: email
  };

  if (!bodyData.user_first_name) {
    delete bodyData.user_first_name;
  }
  if (!bodyData.user_last_name) {
    delete bodyData.user_last_name;
  }

  const url = `${BASE_URL}/signup`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTeamInvite = async (
  ufn,
  uln,
  clockIn,
  clockOut,
  email,
  fm,
  rm,
  joiningDate,
  birthDate,
  nationalID,
  probationDate,
  officeHours,
  user_phone_number = '',
  parent_company_id,
  user_child_company_id = null
) => {
  // if (!isAuthentic()) return;
  const bodyData = {
    user_company_id: parent_company_id,
    user_first_name: ufn,
    user_last_name: uln,
    office_in_timing: clockIn,
    office_out_timing: clockOut,
    user_username: email,
    functional_manager: fm?.id,
    recruitment_manager: rm?.id,
    joining_date: moment(joiningDate).format('YYYY-MM-DD'),
    user_birth_date: moment(birthDate).format('YYYY-MM-DD'),
    cnic: nationalID,
    probation_period: moment(probationDate).format('YYYY-MM-DD'),
    functional_manager_name: `${fm?.user_first_name} ${fm?.user_last_name}`,
    recruitment_manager_name: `${rm?.user_first_name} ${rm?.user_last_name}`,
    user_working_hours: officeHours,
    user_phone_number
  };
  if (user_child_company_id) {
    bodyData.user_child_company_id = user_child_company_id;
  }

  const url = `${BASE_URL}/signup?logged_user=${Number(getUserId())}`;
  if (user_child_company_id) {
    url += `&parent_company_id=${parent_company_id}&user_child_company_id=${user_child_company_id}`;
  }
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};
export const editEmployeeInformation = async (user, employee, values, fm, rm, flag) => {
  const exFlag = flag ? 1 : 0;
  const url = `${BASE_URL}/editEmployeeInformation`;
  const bodyData = {
    user_id: user,
    employee_id: employee,
    user_first_name: values.firstname,
    user_last_name: values.lastname,
    office_in_timing: values.timeIn,
    office_out_timing: values.timeOut,
    attendance_excluding_flag: exFlag,
    user_phone_number: values.phone,
    functional_manager: fm?.id,
    recruitment_manager: rm?.id,
    joining_date: moment(values.joiningDate).format('YYYY-MM-DD'),
    user_birth_date: moment(values.birthDate).format('YYYY-MM-DD'),
    cnic: values.nationalID,
    probation_period: moment(values.probationDate).format('YYYY-MM-DD'),
    user_working_hours: values?.officeHours,
    functional_manager_name: `${fm?.user_first_name} ${fm?.user_last_name}`,
    recruitment_manager_name: `${rm?.user_first_name} ${rm?.user_last_name}`
  };
  // if (Number(getRoleId()) === 3) {
  //   delete bodyData.functional_manager;
  //   delete bodyData.recruitment_manager;
  // }
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const RegisterCompany = async (
  name,
  owner,
  industry,
  address,
  country,
  city,
  inTime,
  outTime,
  description,
  website,
  employees,
  logo,
  lateArrivalThreshold,
  values
) => {
  const dataToSend = {
    user_id: getUserId(),
    company_name: name,
    company_ceo_name: owner,
    industry,
    company_address: address,
    country,
    city,
    office_in_timing: inTime,
    office_out_timing: outTime,
    company_description: description,
    company_url: website,
    num_of_employees: employees,
    company_logo: logo,
    company_auto_id: getCompanyId(),
    late_arrival_threshold: lateArrivalThreshold,
    threshold_for_birthdays_holidays: values.daysThreshold
  };
  const url = `${BASE_URL}/updateCompanyProfile`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToSend)
  });
  const data = await response.json();
  return data;
};

export const getCountries = async () => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getAllCountries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const getUsersAdmin = async (page, page_size, search, order, orderBy) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${page_size}&all_users=${2}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const getOwnersAdmin = async (page, page_size, search) => {
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${page_size}&all_company_owners=${3}&search=${search}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const fetchCities = async (countryId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getCitiesByCountry/${countryId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const postForgotpassword = async (email) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/forgotpassword`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_username: email
    })
  });
  const data = await response.json();
  return data;
};
export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  const url = `${BASE_URL}/updatePassword/${getUserId()}`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    })
  });
  const data = await response.json();
  return data;
};
export const getIndustries = async () => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/get-industries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postEnableuser = async (values) => {
  let user_country = null;
  let user_state = null;
  let user_city = null;
  getCountryCodeIso() != 'null' && (user_country = getCountryCodeIso());
  getUserState() != 'null' && (user_state = getUserState());
  getUserCity() != 'null' && (user_city = getUserCity());

  let val = {
    user_password: values.password,
    user_country: user_country,
    user_city: user_city,
    user_state: user_state
  };

  const url = `${BASE_URL}/enableuser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${values.myuser}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(val)
  });
  const data = await response.json();
  return data;
};

export const passwordCheck = async (values) => {
  const url = `${BASE_URL}/passwordCheck`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${values}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const companyTiming = async (values) => {
  const url = `${BASE_URL}/getCompanyOfficeTimings/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsers = async (order, orderBy) => {
  // if (COMPANY_ID !== null) {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}`;
  if (orderBy && orderBy !== '') url += `?${orderBy}=${order}&`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
  // }
};

export const getTeamList = async (order, orderBy, status, query, page, rows) => {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?`;

  if (orderBy && orderBy !== '') url += `${orderBy}=${order}&`;

  if (status !== null || status !== '') url += `${status}&`;

  if (query !== '') url += `search=${query}&`;

  if (page !== null) url += `page=${page}&`;

  if (rows !== null) url += `page_size=${rows}&`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getSingleProject = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getSingleProject/${id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllProjectCreationData = async () => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getAllProjectCreationData`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompany = async (id) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getCompanyById/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postCreateProject = async (values, dummy, users) => {
  const bodyData = {
    proj_auto_id: values.projectId,
    company_id: Number(getCompanyId()),
    user_id: Number(getUserId()),
    proj_name: values.projectname.trim(),
    proj_flag: 1,
    priority_auto_id: values.prioritylabel,
    proj_short_name: values.shortname.trim(),
    des_project: values.projectdescription,
    project_template_auto_id: values.projecttemplateselect,
    workflow_auto_id: values.workflowselect,
    workflow_bug_auto_id: values.bugworkflowlabel,
    proj_estimated_hr: values.estimatedhours,
    proj_start_date: values.startdate ? formatDate(values.startdate) : '',
    proj_end_date: values.duedate ? formatDate(values.duedate) : '',
    currency_auto_id: values.currencylabel,
    project_status_auto_id: values.projectstatuslabel,
    task_type_auto_id: values.tasktypelabel,
    industry_auto_id: values.industrylabel,
    notify_via_email: dummy,
    project_manager_id: values.projectmanagerlabel,
    Users: users
  };
  if (!bodyData.task_type_auto_id) {
    delete bodyData.task_type_auto_id;
  }
  if (!bodyData.proj_auto_id) {
    delete bodyData.proj_auto_id;
  }
  if (!bodyData.des_project) {
    delete bodyData.des_project;
  }
  if (!bodyData.project_template_auto_id) {
    delete bodyData.project_template_auto_id;
  }
  if (!bodyData.workflow_auto_id) {
    delete bodyData.workflow_auto_id;
  }
  if (!bodyData.workflow_bug_auto_id) {
    delete bodyData.workflow_bug_auto_id;
  }
  if (!bodyData.proj_estimated_hr) {
    delete bodyData.proj_estimated_hr;
  }
  if (!bodyData.proj_start_date) {
    delete bodyData.proj_start_date;
  }
  if (!bodyData.proj_end_date) {
    delete bodyData.proj_end_date;
  }
  if (!bodyData.currency_auto_id) {
    delete bodyData.currency_auto_id;
  }
  if (!bodyData.project_status_auto_id) {
    delete bodyData.project_status_auto_id;
  }
  if (!bodyData.industry_auto_id) {
    delete bodyData.industry_auto_id;
  }
  if (!bodyData.project_manager_id) {
    delete bodyData.project_manager_id;
  }
  if (!bodyData.priority_auto_id) {
    delete bodyData.priority_auto_id;
  }
  const response = await fetch(`${BASE_URL}/createProject`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postCreateTask = async (
  values,
  projectname,
  priorityname,
  taskgroupname,
  tasktypename,
  statusname,
  assignname,
  imagesData,
  estHours,
  dataInfo,
  recurring,
  emailTags
) => {
  const user = getUser();
  const bodyData = {
    proj_task_auto_id: values?.taskautoid,
    user_id: parseInt(getUserId(), 10),
    company_id: parseInt(getCompanyId(), 10),
    proj_auto_id: values?.projectlabel,
    assign_to: values?.assigntolabel,
    assign_name: assignname !== null ? `${assignname[0]?.user_full_name}` : 'Nobody',
    user_first_name: dataInfo?.user_first_name || getUserName().split(' ')[0],
    user_last_name: dataInfo?.user_last_name || getUserName().split(' ')[1],
    proj_name: projectname[0]?.proj_name,
    task_title: values?.tasktitle,
    task_type_auto_id: values?.tasktypelabel,
    task_type_name: tasktypename[0]?.task_type_name,
    priority_auto_id: values?.prioritylabel,
    priority_name: priorityname[0]?.priority_name,
    task_group_auto_id: values?.taskGroupLabel,
    task_group: taskgroupname[0]?.task_group,
    task_detail: values?.description,
    start_date: values?.startdate ? moment(values?.startdate).format('YYYY-MM-DD') : '',
    due_date: values?.duedate ? moment(values?.duedate).format('YYYY-MM-DD') : '',
    task_est_hr: estHours,
    task_related_to_auto_id: 1,
    task_related_name: 'Derived from',
    task_status_auto_id: values?.status || 1,
    status_name: statusname?.length ? statusname[0]?.status_name : 'New',
    notify_via_email: emailTags,
    attachment: imagesData,
    taskActivity: [
      {
        company_id: Number(getCompanyId())
      }
    ]
  };
  if (recurring) {
    recurring[0]?.weekly === 'weekly'
      ? (bodyData.recurring_weekly = recurring)
      : recurring[0]?.daily === 'daily'
      ? (bodyData.recurring = recurring)
      : delete bodyData.recurring;
  }

  if (!bodyData.task_est_hr) {
    delete bodyData.task_est_hr;
  }
  if (!bodyData.due_date) {
    delete bodyData.due_date;
  }
  if (!bodyData.start_date) {
    delete bodyData.start_date;
  }
  if (bodyData.assign_name === 'Nobody') {
    bodyData.assign_to = 0;
  }
  if (bodyData.proj_task_auto_id === '') {
    delete bodyData.proj_task_auto_id;
  }
  // if (bodyData.assign_to === null) {
  //   bodyData.assign_name = 'Nobody';
  // }
  // if (!bodyData.assign_name) {
  //   delete bodyData.assign_name;
  // }
  if (!bodyData.createddate) {
    delete bodyData.createddate;
  }
  if (bodyData.attachment.length === 0 && !bodyData.proj_task_auto_id) {
    delete bodyData.attachment;
  }
  if (bodyData.taskActivity.length === 0) {
    delete bodyData.taskActivity;
  }
  const url = `${BASE_URL}/createTask`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  // const data = await response;
  const data = await response.json();
  return data;
};

export const postTaskWithRecurrimg = async (values, recurring) => {
  const recurringObj =
    recurring?.pattern === 'weekly'
      ? {
          schedule_flag: 1,
          weekly: recurring?.pattern,
          monday: recurring.monday ? 1 : 0,
          tuesday: recurring.tuesday ? 1 : 0,
          wednesday: recurring.wednesday ? 1 : 0,
          thursday: recurring.thursday ? 1 : 0,
          friday: recurring.friday ? 1 : 0,
          saturday: recurring.saturday ? 1 : 0,
          sunday: recurring.sunday ? 1 : 0,
          start_date: recurring?.startdate ? moment(recurring?.startdate).format('YYYY-MM-DD') : '',
          end_date: recurring?.enddate ? moment(recurring?.enddate).format('YYYY-MM-DD') : ''
        }
      : {
          schedule_flag: 1,
          weekly: recurring?.pattern,
          start_date: recurring?.startdate ? moment(recurring?.startdate).format('YYYY-MM-DD') : '',
          end_date: recurring?.enddate ? moment(recurring?.enddate).format('YYYY-MM-DD') : ''
        };
  const bodyData = {
    proj_task_auto_id: values?.proj_task_auto_id,
    user_id: Number(getUserId()),
    company_id: Number(getCompanyId()),
    proj_auto_id: values?.proj_auto_id,
    assign_to: values?.assign_to,
    assign_name: values?.assign_name !== null ? `${values?.assign_name}` : 'Nobody',
    user_first_name: values?.user_first_name || getUserName().split(' ')[0],
    user_last_name: values?.user_last_name || getUserName().split(' ')[1],
    proj_name: values.proj_name,
    task_title: values?.task_title,
    task_type_auto_id: values?.task_type_auto_id,
    task_type_name: values?.task_type_name,
    priority_auto_id: values?.priority_auto_id,
    priority_name: values?.priority_name,
    task_group_auto_id: values?.task_group_auto_id,
    task_group: values?.task_group,
    task_detail: values?.task_detail,
    start_date: values?.startdate ? moment(values?.startdate).format('YYYY-MM-DD') : '',
    due_date: values?.duedate ? moment(values?.duedate).format('YYYY-MM-DD') : '',
    task_est_hr: values?.task_est_hr,
    task_related_to_auto_id: 1,
    task_related_name: 'Derived from',
    task_status_auto_id: values?.task_status_auto_id || 1,
    status_name: values?.length ? values?.status_name : 'New',
    notify_via_email: values?.notify_via_email,
    attachment: values?.TaskAttachment,
    taskActivity: [
      {
        company_id: Number(getCompanyId())
      }
    ]
  };

  recurring?.pattern === 'weekly'
    ? (bodyData.recurring_weekly = [recurringObj])
    : (bodyData.recurring = [recurringObj]);

  if (!bodyData.task_est_hr) {
    delete bodyData.task_est_hr;
  }
  if (!bodyData.due_date) {
    delete bodyData.due_date;
  }
  if (!bodyData.start_date) {
    delete bodyData.start_date;
  }
  if (bodyData.assign_name === 'Nobody') {
    bodyData.assign_to = 0;
  }
  // if (bodyData.assign_to === null) {
  //   bodyData.assign_name = 'Nobody';
  // }
  // if (!bodyData.assign_name) {
  //   delete bodyData.assign_name;
  // }
  if (!bodyData.createddate) {
    delete bodyData.createddate;
  }
  if (bodyData.attachment.length === 0 && !bodyData.proj_task_auto_id) {
    delete bodyData.attachment;
  }
  if (bodyData.taskActivity.length === 0) {
    delete bodyData.taskActivity;
  }

  const url = `${BASE_URL}/createTask`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  // const data = await response;
  const data = await response.json();
  return data;
};

export const getAllCompanyUsers = async (projIds) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}`;
  const changes = projIds ? url + `?proj_auto_id=${projIds}` : null;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getProjectbyUser = async (page, rowsPerPage, search) => {
  // if (!isAuthentic()) return;

  let url = `${BASE_URL}/getProjectbyUser/${getUserId()}/company/${getCompanyId()}?`;
  if (page) {
    url = url + `&page=${page}`;
  }
  if (rowsPerPage) {
    url = url + `&page_size=${rowsPerPage}`;
  }
  if (search) {
    url = url + `&projectName=${search}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllTaskCreationData = async (projId) => {
  const url = `${BASE_URL}/getAllTaskCreationData/${getUserId()}/company/${getCompanyId()}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllTaskProject = async (filteredValues, search, page, rowsPerPage, order, orderBy, kanbanView) => {
  const values = { ...filteredValues };
  if (page) values.page = page;
  if (rowsPerPage) values.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete values.page;
    if (Number(search)) {
      values.refrenceID = search;
    } else values.task_title = search;
  } else {
    delete values.refrenceID;
    delete values.task_title;
  }
  if (values.task_created_start_date) {
    values.task_created_start_date = moment(values.task_created_start_date).format('YYYY-MM-DD');
  } else {
    values.task_created_start_date = '';
  }
  if (values.task_created_end_date) {
    values.task_created_end_date = moment(values.task_created_end_date).format('YYYY-MM-DD');
  } else {
    values.task_created_end_date = '';
  }
  if (!values?.proj_auto_id) {
    values.proj_auto_id = Number(getGlobalProjectId());
  }
  if (values.proj_field) {
    delete values.proj_field;
  }
  if (values.user_id_field) {
    delete values.user_id_field;
  }
  if (values.assign_to_field) {
    delete values.assign_to_field;
  }
  if (values?.assign_to && values?.assign_to[0] === '0') {
    values.assign_to = 0;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getAllTaskProject/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllTaskKanban = async (filteredValues, search, page, rowsPerPage, order, orderBy, kanbanView) => {
  if (page) filteredValues.page = page;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  if (filteredValues?.assign_to && filteredValues?.assign_to[0] === '0') {
    filteredValues.assign_to = 0;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getAllTaskKanban/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const companyCheck = async (name) => {
  const skillObj = {
    company_name: name
  };
  const response = await fetch(`${BASE_URL}/uniqueCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(skillObj)
  });
  const data = await response.json();
  return data;
};

export const getSingleTask = async (comp, user, project, task) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getSingleTaskProjectByUserId/${project}/company/${comp}/task/${user}/${task}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const deleteProject = async (project) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteProjectByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      company_id: Number(getCompanyId()),
      // user_id: Number(getUserId()),
      proj_auto_id: project,
      role_id: Number(getRoleId())
    })
  });
  const data = await response.json();
  return data;
};

export const deleteTaskByUser = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteTaskByUser`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      company_id: parseInt(getCompanyId(), 10),
      user_id: parseInt(getUserId(), 10),
      proj_task_auto_id: id
    })
  });
  const data = await response.json();
  return data;
};

export const deleteTaskByAdmin = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteTaskByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      company_id: parseInt(getCompanyId(), 10),
      user_id: parseInt(getUserId(), 10),
      role_id: parseInt(getRoleId(), 10),
      proj_task_auto_id: id
    })
  });
  const data = await response.json();
  return data;
};

export const submitProfile = async (values, userId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/users/${userId}/profile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  const data = await response.json();
  return data;
};

export const getUserProfileInfo = async (userId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getUserProfileInfo/${userId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const postLeaveTeam = async (id) => {
  // if (!isAuthentic()) return;
  // const jwtToken = localStorage.getItem('jwtToken');
  const url = `${BASE_URL}/leaveTeam`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: id
    })
  });
  const data = await response.json();
  return data;
};

export const postAssignUserRole = async (user_id, role_id) => {
  // if (!isAuthentic()) return;
  // const jwtToken = localStorage.getItem('jwtToken');
  const url = `${BASE_URL}/assignUserRole`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: user_id,
      role_id: role_id
    })
  });
  const data = await response.json();
  return data;
};

export const postTaskAssignChange = async (user, task) => {
  const userId = user?.id ? user?.id : user?.user_id;
  const userName = user?.user_first_name ? `${user?.user_first_name} ${user?.user_last_name}` : user?.user_full_name;
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_title: task?.task_title,
    refrenceID: task?.refrenceID,
    task_type_name: task?.task_type_name,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    assign_to: userId ? userId : 0,
    assign_name: userName ? userName : 'Nobody',
    notify_via_email: task?.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskAssignChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskPriorityChange = async (id, priority, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_auto_id: priority.priority_auto_id,
    priority_name: priority.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskPriorityChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskStatusChange = async (id, status, taskId) => {
  const bodyData = {
    proj_task_auto_id: taskId.toString(),
    status_name: status.status_name ? status.status_name : status,
    task_status_auto_id: id,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1]
  };
  const response = await fetch(`${BASE_URL}/taskStatusChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskDueDateChange = async (value, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    due_date: value ? moment(value).format('YYYY-MM-DD') : '',
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskDueDateChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskStartDateChange = async (value, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    start_date: value ? moment(value).format('YYYY-MM-DD') : '',
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskStartDateChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskTitleChange = async (title, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: title,
    refrenceID: task.refrenceID,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskTitleChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskDesChange = async (des, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_detail: des,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskDescriptionChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskEstHrChange = async (hr, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_detail: task.task_title,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_est_hr: hr,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskEstHoursChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskGroupChange = async (id, name, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_group_auto_id: id,
    task_group: name,
    refrenceID: task?.refrenceID,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task?.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskGroupChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const postTaskTypeChange = async (id, name, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_title: task?.task_title,
    refrenceID: task?.refrenceID,
    task_type_auto_id: id,
    task_type_name: name,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task?.notify_via_email
  };
  const response = await fetch(`${BASE_URL}/taskTypeChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const getUserByProject = async (project) => {
  const url = `${BASE_URL}/getUsersByProject/${project}/Company/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getEstHrByUser = async (userId, project) => {
  const url = `${BASE_URL}/getEstHrByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getSpentHourByUser = async (userId, project) => {
  const url = `${BASE_URL}/getSpentHourByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postInsertTaskTime = async (values, info, date) => {
  // if (!isAuthentic()) return;
  const bodyData = {
    user_id: Number(getUserId()),
    company_id: info.company_id,
    proj_auto_id: info.proj_auto_id,
    proj_task_auto_id: info.proj_task_auto_id,
    proj_name: info.proj_name,
    task_title: info.task_title,
    task_details: info.task_detail,
    task_created_date: moment(info.task_created_date).format('YYYY-MM-DD'),
    start_date: info.start_date ? moment(info.start_date).format('YYYY-MM-DD') : '',
    due_date: info.due_date ? moment(info.due_date).format('YYYY-MM-DD') : '',
    task_spent_hour_date: values.enddate
      ? moment(values.enddate).format('YYYY-MM-DD')
      : moment(date).format('YYYY-MM-DD'),
    task_spent_hour: values.timespent ? values.timespent : values,
    task_timesheet_auto_id: info.task_timesheet_auto_id ? info.task_timesheet_auto_id : ''
  };

  if (bodyData.task_timesheet_auto_id === '') {
    delete bodyData.task_timesheet_auto_id;
  }
  if (bodyData.start_date === '') {
    delete bodyData.start_date;
  }
  if (bodyData.due_date === '') {
    delete bodyData.due_date;
  }
  const url = `${BASE_URL}/insertTaskTime`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};
export const postInsertTaskTimeWeekly = async (arrayOfObjs) => {
  const url = `${BASE_URL}/insertTaskTimeWeekly`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arrayOfObjs)
  });
  const data = await response.json();
  return data;
};

export const getSingleTaskLogs = async (taskData) => {
  // const url = `${BASE_URL}/getSingleTaskLogs/${taskData.proj_task_auto_id}/task/${taskData.proj_auto_id}/project/${taskData.assign_to}`;
  const url = `${BASE_URL}/getSingleTaskLogs/${taskData.proj_task_auto_id}/task/${taskData.proj_auto_id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getProjectEstHr = async (project) => {
  const url = `${BASE_URL}/getProjectEstHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getProjectSpenttHr = async (project) => {
  const url = `${BASE_URL}/getProjectSpenttHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getWeeklyTask = async (start, end, user, search = '') => {
  // let queryparams = '';
  // for (const [key, value] of Object.entries(filteredValues)) {
  //   queryparams += `${key}=${value}&`;
  // }
  // const userId = user ? user : Number(getUserId());
  const url = `${BASE_URL}/getWeeklyTask/${Number(getCompanyId())}/startdate/${formatDate(start)}/endDate/${formatDate(
    end
  )}?assign_to=${user}&searchOnDate=${search}`;
  // const url = `${BASE_URL}/getWeeklyTask/2/startdate/2022-01-27/duedate/2022-02-01`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// export const getMissingTask = async (start, end, user, page, page_size, order, orderBy) => {
//   // let queryparams = '';
//   // for (const [key, value] of Object.entries(filteredValues)) {
//   //   queryparams += `${key}=${value}&`;
//   // }
//   // const userId = user ? user : Number(getUserId());
//   let queryparams = '';
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
//   const url = `${BASE_URL}/missingTaskTimesheet/${Number(getCompanyId())}/startdate/${formatDate(
//     start
//   )}/endDate/${formatDate(end)}?assign_to=${user}&page=${page}&page_size=${page_size}&${queryparams}`;
//   // const url = `${BASE_URL}/getWeeklyTask/2/startdate/2022-01-27/duedate/2022-02-01`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json'
//     }
//   });
//   const data = await response.json();
//   return data;
// };
export const getMissingTask = async (size, page, userid, search, selectedHours, order, orderBy, dateRange, csv = '') => {
  // let queryparams = '';
  // for (const [key, value] of Object.entries(filteredValues)) {
  //   queryparams += `${key}=${value}&`;
  // }
  // const userId = user ? user : Number(getUserId());
  const today = new Date(); // Get current date
  const last30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000); // Calculate date 30 days ago

  const startDate = last30Days.toISOString().slice(0, 10); // Convert date to ISO string and extract YYYY-MM-DD format
  const endDate = today.toISOString().slice(0, 10); // Convert current date to ISO string and extract YYYY-MM-DD format

  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
   if (csv === '') queryparams += 'data=1&';
  const url = `${BASE_URL}/missingTaskTimesheet/${Number(
    getCompanyId()
  )}?page_size=${size}&page=${page}&assign_to=${userid}&givenHours=${selectedHours}&search=${search}&${queryparams}&startDate=${
    dateRange[0] ? dateRange[0] : startDate
  }&endDate=${dateRange[1] ? dateRange[1] : endDate}${csv}`;
  // const url = `${BASE_URL}/getWeeklyTask/2/startdate/2022-01-27/duedate/2022-02-01`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const getListViewTask = async (users, projects, taskStatus, dates, rowsPerPage, page, query) => {
  // const url = `${BASE_URL}/getViewList/${getCompanyId()}/?page_size=${size}&page=${
  //   page + 1
  // }&user_id=${userid}&search=${search}&startDate=${dates[0] ? dates[0] : ''}&dueDate=${
  //   dateRange[1] ? dateRange[1] : ''
  // }`;
  const url = `${BASE_URL}/getViewList/${Number(
    getCompanyId()
  )}?assign_to=${users.toString()}&proj_auto_id=${projects.toString()}&status_name=${taskStatus.toString()}&search=${query}&task_created_date=${
    dates[0] ? dates[0] : ''
  }&due_date=${dates[1] ? dates[1] : ''}&page=${page}&page_size=${rowsPerPage}`;
  // const url = `${BASE_URL}/getWeeklyTask/2/startdate/2022-01-27/duedate/2022-02-01`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const deleteTaskTimeLog = async (info) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteSingleTaskLogs`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      proj_task_auto_id: info.proj_task_auto_id,
      proj_auto_id: info.proj_auto_id,
      user_id: info.user_id,
      task_timesheet_auto_id: info.task_timesheet_auto_id
    })
  });
  const data = await response.json();
  return data;
};

export const postInsertTimeLogs = async (info, values, spentHours) => {
  // if (!isAuthentic()) return;
  const url = `${BASE_URL}/insertTimeLogs`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: 1,
      company_id: 1,
      time_clock_status: 'signing in',
      away_auto_id: 1,
      away_time: 'Away for 5 minutes'
    })
  });
  const data = await response.json();
  return data;
};

export const getAway = async (project) => {
  const url = `${BASE_URL}/getAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// Sign in and away status_id's and status strings for dropdown
export const getSignAway = async () => {
  const url = `${BASE_URL}/getSignAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// Get time logs by company
export const getTimeLogByCompany = async () => {
  const url = `${BASE_URL}/getTimeLogs/${getUserId()}/Company/${getCompanyId()}`;
  // const url = `${BASE_URL}/getTimeLogs/1/Company/1`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// Get recent swipes by user
export const getRecentSwipes = async () => {
  // const url = `${BASE_URL}/getTimeLogs/${getUserId()}/Company/${getCompanyId()}`;
  const url = `${BASE_URL}/getRecentSwipes/${getUserId()}/company/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// Get recent swipes by user
export const getSignInOut = async () => {
  const url = `${BASE_URL}/getClockInByUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

// Get recent breaks by user
export const getBreaks = async () => {
  const url = `${BASE_URL}/getAwayBackByUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postSignInOut = async (status, channel) => {
  const url = `${BASE_URL}/insertAttendance`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
      company_id: Number(`${getCompanyId()}`),
      attendance_name: status,
      channel:
        channel === 'office'
          ? status === 'Signing in'
            ? `${status} from ${channel}`
            : `${status}`
          : status === 'Signing in'
          ? `${status} ${channel}`
          : `${status}`,
      channel_flag: channel === 'office' ? 1 : 0
    })
  });
  const data = await response.json();
  return data;
};

export const postAwayBack = async (breakReason) => {
  const url = `${BASE_URL}/insertBreak`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
      company_id: Number(`${getCompanyId()}`),
      break_reason: breakReason
    })
  });
  const data = await response.json();
  return data;
};

export const getBreakStatus = async () => {
  const url = `${BASE_URL}/getBreakStatus`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAbsentEmployee = async () => {
  const url = `${BASE_URL}/getAbsentEmployee/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllEmployeeStatus = async () => {
  const url = `${BASE_URL}/getAllEmployeeStatus/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getEmployeeLastStatus = async () => {
  const url = `${BASE_URL}/getEmployeeLastStatus/${getUserId()}/company/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllAwayEmployee = async () => {
  const url = `${BASE_URL}/getAllAwayEmployee/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getSignInMemberStatus = async () => {
  const url = `${BASE_URL}/getSignInMemberStatus/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getEmployeesOfficeData = async (page, rowsPerPage, order, orderBy, search) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getEmployeesOfficeData/${getCompanyId()}?page=${page}&page_size=${rowsPerPage}&search=${search}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskStatusesbyUser = async () => {
  const url = `${BASE_URL}/getProjectTaskStatusesbyUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskStatusesbyUserAndProj = async (userId, projId) => {
  const url = `${BASE_URL}/getProjectTaskStatusesbyUserAndProjectId/${userId}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAttendanceReport = async (size, page, userid, search, dateRange, csv = '') => {
  const url = `${BASE_URL}/getAttendanceReport/company/${getCompanyId()}?page_size=${size}&page=${page}&user_id=${userid}&search=${search}&startDate=${
    dateRange[0] ? dateRange[0] : ''
  }&dueDate=${dateRange[1] ? dateRange[1] : ''}${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const getallpayroll = async (size, page, userid, search, order, orderBy, dateRange, csv = '') => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllPayroll/?page_size=${size}&page=${page}&user_id=${userid}&search=${search}&${queryparams}&effective_date_start_date=${
    dateRange[0] ? dateRange[0] : ''
  }&effective_date_end_date=${dateRange[1] ? dateRange[1] : ''}${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
// export const getallpayroll = async (page, rowsPerPage, order, orderBy, query, userId) => {
//   let queryparams = '';
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
//   let url = `${BASE_URL}/getAllPayroll?page=${page}&page_size=${rowsPerPage}&search=${query}&${queryparams}`;
//   if (userId) url = url + `&user_id=${userId}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json'
//     }
//   });
//   const data = await response.json();
//   return data;
// };
export const generateTaskListPdf = async (filter, csv = '') => {
  const url = `${BASE_URL}/generateTaskListPdf/${getCompanyId()}?priority_name=${
    filter.priority_name ? filter.priority_name : ''
  }&status_name=${filter.status_name ? filter.status_name : ''}&task_type_name=${
    filter.task_type_name ? filter.task_type_name.toString() : ''
  }&user_id=${filter.user_id ? filter.user_id.toString() : ''}&task_overdue=${
    filter.task_overdue ? filter.task_overdue : ''
  }&task_title=${filter.task_title ? filter.task_title : ''}&assign_to=${
    filter.assign_to ? filter.assign_to.toString() : ''
  }&proj_auto_id=${filter.proj_auto_id ? filter.proj_auto_id.toString() : ''}&task_today=${
    filter.task_today ? filter.task_today : ''
  }${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const timeSheetListLogs = async (users, projects, taskStatus, dates, csv = '') => {
  let filterDates = dates && dates[0] && dates[1] ? `&task_created_date=${dates[0]}&task_updated_date=${dates[1]}` : '';
  const url = `${BASE_URL}/timeSheetViewListLogs/${getCompanyId()}?assign_to=${
    users ? users.toString() : ''
  }&status_name=${taskStatus ? taskStatus.toString() : ''}&proj_auto_id=${
    projects ? projects.toString() : ''
  }${filterDates}${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const timeSheetViewListLogs = async (users, projects, taskStatus, startDate = '', endDate = '', csv = '') => {
  let filterDates =
    startDate && endDate
      ? `&task_created_date=${moment(startDate).format('YYYY-MM-DD')}&task_updated_date=${moment(endDate).format(
          'YYYY-MM-DD'
        )}`
      : '';
  const url = `${BASE_URL}/timeSheetViewListLogs/${getCompanyId()}?assign_to=${
    users ? users.toString() : ''
  }&status_name=${taskStatus ? taskStatus.toString() : ''}&proj_auto_id=${
    projects ? projects.toString() : ''
  }${filterDates}${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getWeeklyTaskPdf = async (start, end, user) => {
  const url = `${BASE_URL}/getWeeklyTaskPdf/${getCompanyId()}?assign_to=${
    user ? user : Number(getUserId())
  }&task_created_date=${start ? start : ''}&task_updated_date=${end ? end : ''}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postTaskGroup = async (values) => {
  const url = `${BASE_URL}/taskGroupGeneric`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(getUserId()),
      company_id: Number(getCompanyId()),
      default_flag: 1,
      task_group_generic_flag: 1,
      task_group: values.taskGrouptitle
      // description: values.taskGroupDes
    })
  });
  const data = await response.json();
  return data;
};

export const postTaskType = async (name) => {
  const url = `${BASE_URL}/taskTypeDynamic`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(getUserId()),
      company_id: Number(getCompanyId()),
      flag: 1,
      task_type_flag: 1,
      task_type_name: name
      // description: values.taskGroupDes
    })
  });
  const data = await response.json();
  return data;
};

export const getTaskGroupGeneric = async () => {
  const url = `${BASE_URL}/getTaskGroupGeneric/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskActivity = async (userId, taskId) => {
  const url = `${BASE_URL}/getTaskActivity/${userId}/task/${taskId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskComments = async (compId, projId, taskId) => {
  const url = `${BASE_URL}/getTaskComments/${compId}/company/${projId}/task/${taskId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postTaskComment = async (comments, compId, userId, projId, taskId) => {
  const url = `${BASE_URL}/taskComment`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(userId),
      company_id: Number(compId),
      proj_auto_id: Number(projId),
      proj_task_auto_id: Number(taskId),
      task_comment: comments,
      user_first_name: getUserName().split(' ')[0],
      user_last_name: getUserName().split(' ')[1],
      notify_via_email: 'manage@unikoop.nl'
    })
  });
  const data = await response.json();
  return data;
};

export const getTasksView = async (userId, compId, projId) => {
  const url = `${BASE_URL}/getMytaskView/${userId}/company/${compId}/project/${projId}?page=1&page_size=25`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getOverdueTasksView = async (userId, compId, projId) => {
  const url = `${BASE_URL}/getMyOverduetaskView/${userId}/company/${compId}/project/${projId}?page=1&page_size=25`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getGeneratePdf = async () => {
  const url = `${BASE_URL}/generatePdf`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`)
    })
  });
  const data = await response.json();
  return data;
};

export const deleteTaskGroup = async (id, name) => {
  const url = `${BASE_URL}/deleteTaskGroupDynamic`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      task_group_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      // group_id: 1,
      // group_name: 'Story',
      role_id: Number(`${getRoleId()}`)
    })
  });
  const data = await response.json();
  return data;
};

export const deleteTaskType = async (id, name) => {
  const url = `${BASE_URL}/deleteTaskTypeDynamic`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      task_type_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      // type_id: 1,
      // type_name: 'Story',
      role_id: Number(`${getRoleId()}`)
    })
  });
  const data = await response.json();
  return data;
};

// export const postSaveFilter = async (name, projIds, userIds, taskTypeIds, priorityIds, statusIds, date) => {
export const postSaveFilter = async (name, filteredValues) => {
  const bodyDate = {
    user_id: Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    proj_auto_id: filteredValues.proj_auto_id ? filteredValues.proj_auto_id.toString() : '',
    resource: filteredValues.assign_to ? filteredValues.assign_to.toString() : '',
    task_status_auto_id: filteredValues.status_name ? filteredValues.status_name.toString() : '',
    priority_auto_id: filteredValues.priority_name ? filteredValues.priority_name.toString() : '',
    task_type_auto_id: filteredValues.task_type_name ? filteredValues.task_type_name.toString() : '',
    filter_name: name,
    created_date: filteredValues.task_created_date ? filteredValues.task_created_date : '',
    // task_group_auto_id: '1,5,3,4',
    task_created_user_id: filteredValues.user_id ? filteredValues.user_id.toString() : '',
    task_over_due: filteredValues.task_overdue === 'Overdue' ? filteredValues.task_overdue : '',
    task_today: filteredValues.task_overdue === 'today' ? filteredValues.task_overdue : ''
    // task_custom_range: '2022-09-28 10:10:30,2022-09-28 10:10:30'
  };
  if (bodyDate.proj_auto_id === '') delete bodyDate.proj_auto_id;
  if (bodyDate.resource === '') delete bodyDate.resource;
  if (bodyDate.task_status_auto_id === '') delete bodyDate.task_status_auto_id;
  if (bodyDate.priority_auto_id === '') delete bodyDate.priority_auto_id;
  if (bodyDate.task_type_auto_id === '') delete bodyDate.task_type_auto_id;
  if (bodyDate.created_date === '') delete bodyDate.created_date;
  if (bodyDate.task_created_user_id === '') delete bodyDate.task_created_user_id;
  if (bodyDate.task_over_due === '') delete bodyDate.task_created_user_id;
  if (bodyDate.task_today === '') delete bodyDate.task_created_user_id;

  const url = `${BASE_URL}/savedFilter`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const getSavedFilter = async () => {
  const url = `${BASE_URL}/getSavedFilter/${Number(`${getUserId()}`)}/Company/${Number(getCompanyId())}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getKanbanNewTask = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getKanbanNewTask/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getKanbanInprogress = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;
  const checkurl = `${BASE_URL}/getKanbanInprogress/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getKanbanResolved = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getKanbanResolved/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getKanbanClosed = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;
  const checkurl = `${BASE_URL}/getKanbanClosed/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const projectDashboard = async (compId, projId) => {
  const url = `${BASE_URL}/projectDashboard/companyId/${compId}/projectId/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskActivityLogByProject = async (projId, compId) => {
  const url = `${BASE_URL}/getTaskActivityLogByProject/project/${projId}/company/${compId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getUserProjects = async () => {
  const url = `${BASE_URL}/getUserProjects/company/${getCompanyId()}/user/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getTaskTypeStatusPriority = async () => {
  const url = `${BASE_URL}/getTaskTypeStatusPriority`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getUserRole = async () => {
  const url = `${BASE_URL}/userRole/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getLateEmployeeStatus = async () => {
  const url = `${BASE_URL}/getLateEmployeeStatus/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getUsersOnLeave = async () => {
  const url = `${BASE_URL}/getUsersOnLeave`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postDisableUser = async (usedId, no) => {
  const bodyDate = {
    user_is_active: no,
    id: usedId
  };

  const url = `${BASE_URL}/disableUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const deleteSavedFilter = async (id) => {
  const url = `${BASE_URL}/deleteSavedFilter`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      saved_filter_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      user_id: Number(`${getUserId()}`)
    })
  });
  const data = await response.json();
  return data;
};

export const postCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days.string)
  };

  const url = `${BASE_URL}/companyLeavePlanner`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const updateCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    comp_leave_planner_id: info?.id,
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days?.string)
  };

  const url = `${BASE_URL}/updateCompanyLeavePlanner`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const getCompanyLeavePlan = async (search) => {
  let url = `${BASE_URL}/getCompanyLeavePlan/${getCompanyId()}?`;
  if (search) {
    url = url + `&search=${search}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const deleteCompanyLeavePlanner = async (id) => {
  const url = `${BASE_URL}/deleteCompanyLeavePlannerType`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      comp_leave_planner_id: id,
      company_id: Number(`${getCompanyId()}`),
      flag: 0
    })
  });
  const data = await response.json();
  return data;
};

export const getEarnLeaveByUser = async (id, leaveId) => {
  // const info = id ? id : Number(localStorage.getItem('userId'));
  const url = `${BASE_URL}/earnLeaveByUser/${id}/user/${`${getUserSession().slice(0, 4)}`}/year/${leaveId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getAllCompanyLeaves = async (page, size, filterValues, order, orderBy, search, year) => {
  // ?approved=1&pending=0&page=1&page_size=25
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  for (const [key, value] of Object.entries(filterValues)) {
    queryparams += `${key}=${value}&`;
  }
  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(
    `${getCompanyId()}`
  )}/company/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getLeaveForApprovalsCheck = async () => {
  const url = `${BASE_URL}/getLeaveForApprovals/${new Date().getFullYear()}?page=1&page_size=25&search=`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getLeaveForApprovals = async (page, size, filterValues, search, year) => {
  // ?approved=1&pending=0&page=1&page_size=25
  // debugger; // eslint-disable-line no-debugger
  let queryparams = '';
  for (const [key, value] of Object.entries(filterValues)) {
    queryparams += `${key}=${value}&`;
  }
  const url = `${BASE_URL}/getLeaveForApprovals/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const getMyLeaves = async (page, size, order, orderBy, year) => {
  // ?approved=1&pending=0&page=1&page_size=25
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}&page=${page}&page_size=${size}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
// {
//   "balance": 2.5,
//   "attachment": "",
//   "reason": "",
//   "show_reason_flag": 1,
//   "emergency_flag": 1,
// }

export const postLeaveApplyByUser = async (info, values) => {
  const bodyDate = {
    user_id: info?.user_id ? info?.user_id : Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    leave_type_name: values?.leaveType.leave_type_name,
    comp_leave_planner_id: values?.leaveType.comp_leave_planner_id,
    start_date: moment(info?.start_date).format('YYYY-MM-DD'),
    end_date: moment(info?.end_date).format('YYYY-MM-DD'),
    day: info?.days,
    balance: info?.balance,
    // entitlement_id: values?.user?.entitlement_id,
    entitlement_id: info?.entitlement_id,
    session: info?.session_name,
    reason: values?.reason,
    show_reason_flag: values?.showReason ? 1 : 0,
    emergency_flag: values?.emergencyLeave ? 1 : 0
  };

  const url = `${BASE_URL}/leaveApplyByUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

// export const postLeaveApprove = async (info, no) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no
//   };

//   const url = `${BASE_URL}/leaveApproveBy`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(bodyDate)
//   });
//   const data = await response.json();
//   return data;
// };

export const postCompanyHoliday = async (info, id) => {
  const bodyDate = {
    user_id: Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    title: info?.code,
    description: info?.description,
    start_date: moment(info?.startdate).format('YYYY-MM-DD'),
    end_date: moment(info?.enddate).format('YYYY-MM-DD'),
    active: info?.active ? 1 : 0,
    holidays_id: id
  };

  if (!bodyDate.holidays_id) delete bodyDate.holidays_id;

  const url = `${BASE_URL}/companyHolidays`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const getAllCompanyHolidays = async (page, rowsPerPage, order, orderBy, search, year) => {
  // ?page_size=5&page=1
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  if (year) {
    queryparams += `year=${year}&`;
  }
  // const url = `${BASE_URL}/getAllCompanyHolidays/${Number(
  //   `${getCompanyId()}`
  // )}?page=${page}&page_size=${rowsPerPage}&search=${query}&${queryparams}`;
  let url = `${BASE_URL}/getAllCompanyHolidays/${Number(`${getCompanyId()}`)}?${queryparams}`;
  if (page) {
    url = url + `page=${page}`;
  }
  if (rowsPerPage) {
    url = url + `&page_size=${rowsPerPage}`;
  }
  if (search) {
    url = url + `&search=${search}`;
  }
  // if (queryparams) {
  //   url = url + `&${queryparams}`;
  // }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompanySchedule = async (startD, endD) => {
  // ?page_size=5&page=1
  const url = `${BASE_URL}/companySchedule/${Number(`${getCompanyId()}`)}/company/${moment(startD).format(
    'YYYY-MM-DD'
  )}/date/${moment(endD).format('YYYY-MM-DD')}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getUserLeaves = async (year) => {
  // ?page=1&page_size=5
  const url = `${BASE_URL}/getUserLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const createChildCompany = async (values, indusObj) => {
  const url = `${BASE_URL}/createChildCompany`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      company_email: values.companyEmail,
      display_name: values.displayName,
      company_name: values.companyName,
      company_ceo_name: values.companyOwner,
      industry: values.industry,
      num_of_employees: values.employees,
      country: values.country,
      city: values.city,
      company_address: values.address,
      company_url: values.website,
      company_logo: values.avatarUrl,
      company_description: values.description,
      user_id: Number(`${getUserId()}`),
      industry_id: indusObj?.industry_auto_id,
      parent_company_id: Number(`${getCompanyId()}`),
      company_is_parent: 0
      // late_arrival_threshold: values?.lateArrivalThreshold
    })
  });
  const data = await response.json();
  return data;
};

export const updateChildCompany = async (values, indusObj, id) => {
  const url = `${BASE_URL}/updateChildCompany/${getCompanyId()}/${id}`;
  const bodyData = {
    company_email: values.companyEmail,
    display_name: values.displayName,
    company_name: values.companyName,
    company_ceo_name: values.companyOwner,
    industry: values.industry,
    num_of_employees: values.employees,
    country: values.country,
    city: values.city,
    company_address: values.address,
    company_url: values.website,
    company_logo: values.avatarUrl,
    company_description: values.description,
    user_id: Number(`${getUserId()}`),
    industry_id: indusObj?.industry_auto_id,
    parent_company_id: Number(`${getCompanyId()}`),
    company_is_parent: 0,
    company_auto_id: values.companyAutoId
    // late_arrival_threshold: values?.lateArrivalThreshold
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const getChildCompany = async (single = true, id) => {
  const childcompany = id ? `&childCompId=${id || ''}` : '';
  let allComp = null;
  if (single) {
    allComp = `&allChildParentCompId=${Number(getCompanyId()) || ''}`;
  } else {
    allComp = `&ParentCompId=${Number(getCompanyId()) || ''}`;
  }
  const url = `${BASE_URL}/getChildCompany?${allComp}${childcompany}`; // for all verified or unverified sub companies only
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompanyEarningPolicy = async () => {
  const url = `${BASE_URL}/getCompanyEarningPolicy/${Number(`${getCompanyId()}`)}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getEarningPolicyData = async () => {
  const url = `${BASE_URL}/getEarningPolicyData`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const earningPolicy = async (values) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    title: values.code,
    description: values.description,
    earning_method_id: values.method.id,
    earning_accrual_period_id: values.period.id,
    earning_earn_at_id: values.earnAt.id,
    active_flag: values.active ? 1 : 0,
    earning_policy_id: values.earningPolicyId
  };
  if (!bodyData.earning_policy_id) delete bodyData.earning_policy_id;
  const url = `${BASE_URL}/earningPolicy`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const getUserEntitlement = async (id) => {
  const url = `${BASE_URL}/getUserEntitlement/${id}/user/${Number(getUserSession().slice(0, 4))}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompanyEntitlement = async (startDate, page, rowsPerPage, order, orderBy, search) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getCompanyEntitlement/${Number(getCompanyId())}?&earning_start=${startDate?.slice(
    11,
    15
  )}-01-01&valid_until=${startDate?.slice(11, 15)}-12-31&year=${startDate?.slice(
    11,
    15
  )}&page=${page}&page_size=${rowsPerPage}&search=${search}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getEntitlementBalance = async (values) => {
  const bodyData = {
    earning_start: moment(values?.startDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    year: String(values?.year),
    user_id: values.user.id,
    entitlement_id: values.entitlementId ? values.entitlementId : ''
    // yearSelect: values.yearSelect
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/getBalanceTakenCalculator`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const userEntitlement = async (values) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    user_id: Number(values?.user?.id),
    // user:values?.user_first_name,
    type: values?.leaveType?.leave_type_name,
    leave_id: values?.leaveType?.comp_leave_planner_id,
    earning_policy: values?.earningPolicy?.title,
    earning_start: moment(values?.startDate).format('YYYY-MM-DD'),
    valid_until: moment(values?.endDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    entitlement_id: Number(values?.entitlementId),
    year: String(values?.year)
    // yearSelect: values.yearSelect
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/userEntitlement`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const updateCompanyEntitlement = async (values) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    user_id: Number(values?.user?.id),
    type: values?.leaveType?.leave_type_name,
    leave_id: values?.leaveType?.comp_leave_planner_id,
    earning_policy: values?.earningPolicy?.title,
    earning_start: moment(values?.startDate).format('YYYY-MM-DD'),
    valid_until: moment(values?.endDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    entitlement_id: Number(values?.entitlementId),
    year: String(values?.year)
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/updateCompanyEntitlement`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const deleteEarningPolicy = async (id) => {
  const url = `${BASE_URL}/deleteEarningPolicy`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      earning_policy_id: id,
      company_id: Number(`${getCompanyId()}`)
    })
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersWithEntitlementID = async (year, flagId) => {
  // flag =1 for user without entitlement
  // flag =2 for all user
  // no flag for all user with entitlement

  const url = `${BASE_URL}/getCompanyUsersWithEntitlementID/${getCompanyId()}/company/${year}?flag=${flagId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const getuserpayroll = async (page, rowsPerPage, order, orderBy, query) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getUserPayroll?page=${page}&page_size=${rowsPerPage}&search=${query}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const getCurrency = async () => {
  const url = `${BASE_URL}/getCurrency`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const updatePayroll = async (values) => {
  const bodyData = {
    basic_salary: Number(values?.basicSalary),
    company_id: Number(getCompanyId()),
    effective_date: moment(values?.startDate).format('YYYY-MM-DD'),
    next_review_date: moment(values?.endDate).format('YYYY-MM-DD'),
    currency: values?.currency?.currency_code,
    user_id: Number(values?.user?.id),
    payroll_id: values?.payrollId,
    flag: 1
  };

  if (bodyData?.payroll_id === '') delete bodyData?.payroll_id;

  const url = `${BASE_URL}/updatePayroll`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};
export const deletePayroll = async (id, payrollId) => {
  const url = `${BASE_URL}/deleteUserPayRoll`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: id,
      payroll_id: payrollId
    })
  });
  const data = await response.json();
  return data;
};
export const getCompanyUsersWithoutEntitlementID = async (year) => {
  // flag =1 for user without entitlement
  // flag =2 for all user
  // no flag for all user with entitlement

  const url = `${BASE_URL}/getCompanyUsersWithEntitlementID/${getCompanyId()}/company/${year}?flag=2`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const deleteUserLeave = async (info) => {
  const url = `${BASE_URL}/deleteUserLeave`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      employee_leave_id: info?.employee_leave_id,
      company_id: Number(`${getCompanyId()}`),
      user_id: info?.user_id
    })
  });
  const data = await response.json();
  return data;
};

export const updateWorkFlow = async (values) => {
  const bodyData = {
    approval_workflow_id: values?.approval_workflow_id,
    company_id: Number(getCompanyId()),
    user_id: Number(getUserId()),
    title: values?.code,
    description: values?.description,
    f_approver: values?.firstApprover?.role_auto_id,
    s_approver: values?.secondApprover?.role_auto_id,
    active_flag: values?.active ? 1 : 0,
    notify_additional_recipients_id: values?.tags?.map((x) => x?.user_username).join(',')
    // approver: { first: 1, second: 3, third: 5 }
  };

  if (bodyData?.approval_workflow_id === '') delete bodyData?.approval_workflow_id;

  const url = `${BASE_URL}/updateWorkFlow`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const getApprovalWorkFLow = async () => {
  const url = `${BASE_URL}/getApprovalWorkFLow`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const deleteWorkFlow = async (info) => {
  const url = `${BASE_URL}/deleteWorkFlow`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      approval_workflow_id: info.approval_workflow_id,
      title: info.title
    })
  });
  const data = await response.json();
  return data;
};

export const GetCompanyWorkday = async () => {
  const url = `${BASE_URL}/getCompanyWorkday`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const updateCompanyWorkday = async (info) => {
  const bodyData = {
    title: info.name,
    description: info.description,
    active: info.active ? 1 : 0,
    monday: info.monday ? 1 : 0,
    tuesday: info.tuesday ? 1 : 0,
    wednesday: info.wednesday ? 1 : 0,
    thursday: info.thursday ? 1 : 0,
    friday: info.friday ? 1 : 0,
    saturday: info.saturday ? 1 : 0,
    sunday: info.sunday ? 1 : 0,
    workday_id: info.id
  };

  if (bodyData?.workday_id === 0 || !bodyData?.workday_id) delete bodyData?.workday_id;

  const url = `${BASE_URL}/updateCompanyWorkday`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};

export const deleteWorkday = async (info) => {
  const url = `${BASE_URL}/deleteWorkday`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      workday_id: info.workday_id,
      title: info.title
    })
  });
  const data = await response.json();
  return data;
};
export const deleteHoliday = async (info) => {
  const url = `${BASE_URL}/deleteCompanyHoliday`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      holidays_id: info.holidays_id,
      company_id: info.company_id
    })
  });
  const data = await response.json();
  return data;
};

export const GetManagers = async () => {
  const url = `${BASE_URL}/getFunctionalProjectManagers`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersForList = async (page, rows, query) => {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?sortByName=asc&`;
  if (query && query !== '') url += `search=${query}&`;

  if (page !== null) url += `page=${page}&`;

  if (rows !== null) url += `page_size=${rows}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const GetAllComingHolidays = async (days) => {
  const url = `${BASE_URL}/getAllCompanyComingHolidays?days=${days}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const GetCompanyComingUserBirthDay = async (days) => {
  const url = `${BASE_URL}/getCompanyComingUserBirthDay?days=${days}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const GetUserLastLogin = async () => {
  const url = `${BASE_URL}/getUserLastLogin`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const GetUpcomingSalaryReviews = async () => {
  const url = `${BASE_URL}/getComingCompanySalaryReview`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  // debugger;
  return data;
};
export const GetCompanyComingLeavesApproval = async () => {
  const url = `${BASE_URL}/getComingCompanyLeaveApprove`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const GetCompanyComingUserAnniversary = async () => {
  const url = `${BASE_URL}/getUpComingUserAnniversary`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const GetCompanyComingEndProbation = async () => {
  const url = `${BASE_URL}/getUpComingEndProbation`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const deleteEntitlement = async (info) => {
  const url = `${BASE_URL}/deleteCompanyEntitlement/${info.entitlement_id}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveByFirstApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no
  };

  const url = `${BASE_URL}/leaveApproveByFirstApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveBySecondApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no
  };

  const url = `${BASE_URL}/leaveApproveBySecondApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveByHR = async (info, no, comment) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
    message: comment
  };

  const url = `${BASE_URL}/leaveApproveByHr/2023`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};
export const GetAllRoles = async () => {
  const url = `${BASE_URL}/adminGetUserRoles`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const adminUserEnableDisable = async (usedId, no) => {
  const bodyDate = {
    user_is_active: no,
    id: usedId
  };

  const url = `${BASE_URL}/adminUserEnableDisable`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyDate)
  });
  const data = await response.json();
  return data;
};
export const getCompaniesAdmin = async (page, page_size, search, order, orderBy) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetallcompanies?page=${page}&page_size=${page_size}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const adminDeleteUser = async (usedId = '') => {
  const url = `${BASE_URL}/adminDeleteUser/${userId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const adminDeleteCompany = async (id = '') => {
  const url = `${BASE_URL}/adminDeleteCompany/${id}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const adminCompanyAction = async (id = '', flag = -1) => {
  const bodyData = {
    company_flag: flag
  };
  const url = `${BASE_URL}/adminCompanyAction/${id}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};
export const adminGetEmailTemplate = async () => {
  const url = `${BASE_URL}/adminGetEmailTemplate`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const GetAllEmailSettings = async () => {
  const url = `${BASE_URL}/adminGetEmailSetting`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();
  return data;
};
export const adminUpdateEmailTemplate = async (bodyData = []) => {
  const url = `${BASE_URL}/adminUpdateEmailTemplate`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  });
  const data = await response.json();
  return data;
};
export const enableCompany = async (params_token) => {
  let urlToken = params_token;
  const response = await fetch(`${BASE_URL}/enableCompany`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${urlToken}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

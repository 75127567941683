// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/forget-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  emailSent: '/email-sent',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  superAdmin: {
    admin: path(ROOTS_ADMIN, '/charts')
  },
  emailConfigurations: {
    email: path(ROOTS_ADMIN, '/email-configuration')
  },
  emailTemplates: {
    templates: path(ROOTS_ADMIN, '/email-templates')
  },
  roleManagement: {
    roles: path(ROOTS_ADMIN, '/role-management')
  },
  permissionManagement: {
    permissions: path(ROOTS_ADMIN, '/permission-management')
  },
  userManagement: {
    users: path(ROOTS_ADMIN, '/user-management')
  },
  companyManagement: {
    companies: path(ROOTS_ADMIN, '/company-management')
  },
  ownerManagement: {
    owners: path(ROOTS_ADMIN, '/owner-management')
  },
  generalSettingsManagement: {
    general: path(ROOTS_ADMIN, '/general-settings')
  }
};
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    // admin: path(ROOTS_DASHBOARD, '/admin'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  // projects: path(ROOTS_DASHBOARD, '/projects'),
  // createProject: path(ROOTS_DASHBOARD, '/create-project'),
  // tasks: path(ROOTS_DASHBOARD, '/tasks'),
  // createTask: path(ROOTS_DASHBOARD, '/create-task'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    view: path(ROOTS_DASHBOARD, '/user/view'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),
    // company: path(ROOTS_DASHBOARD, '/user/company'),
    // company_edit: path(ROOTS_DASHBOARD, '/user/company/edit'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new')
    // team: path(ROOTS_DASHBOARD, '/user/team'),
    // newTeam: path(ROOTS_DASHBOARD, '/user/new-team'),
    // editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    // settings: path(ROOTS_DASHBOARD, '/user/settings')
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    change_password: path(ROOTS_DASHBOARD, '/account/change-password')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  projects: {
    root: path(ROOTS_DASHBOARD, '/projects/all'),
    new: path(ROOTS_DASHBOARD, '/projects/new'),
    view: path(ROOTS_DASHBOARD, '/projects/view'),
    edit: path(ROOTS_DASHBOARD, '/projects/edit')
  },
  tasks: {
    // root: path(ROOTS_DASHBOARD, '/tasks/all'),
    root: path(ROOTS_DASHBOARD, '/tasks/list'),
    board: path(ROOTS_DASHBOARD, '/tasks/board'),
    new: path(ROOTS_DASHBOARD, '/tasks/new'),
    view: path(ROOTS_DASHBOARD, '/tasks/view'),
    edit: path(ROOTS_DASHBOARD, '/tasks/edit')
  },
  timesheet: {
    root: path(ROOTS_DASHBOARD, '/timesheet'),
    weekly: path(ROOTS_DASHBOARD, '/timesheet/weekly'),
    list: path(ROOTS_DASHBOARD, '/timesheet/list'),
    missingList: path(ROOTS_DASHBOARD, '/timesheet/missing-timesheet'),
    calendar: path(ROOTS_DASHBOARD, '/timesheet/calendar')
  },
  team: {
    root: path(ROOTS_DASHBOARD, '/team/all'),
    new: path(ROOTS_DASHBOARD, '/team/new'),
    edit: path(ROOTS_DASHBOARD, '/team/edit'),
    view: path(ROOTS_DASHBOARD, '/team/view')
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company/view'),
    edit: path(ROOTS_DASHBOARD, '/company/edit'),
    add: path(ROOTS_DASHBOARD, '/company/subcompany')
  },
  attendance: {
    root: path(ROOTS_DASHBOARD, '/attendance/view'),
    // dashboard: path(ROOTS_DASHBOARD, '/attendance/dashboard'),
    timing: path(ROOTS_DASHBOARD, '/attendance/timing'),
    log: path(ROOTS_DASHBOARD, '/attendance/log')
  },
  leaves: {
    root: path(ROOTS_DASHBOARD, '/leaves/all'),
    user: path(ROOTS_DASHBOARD, '/leaves/my-leaves'),
    pendingApproval: path(ROOTS_DASHBOARD, '/leaves/pending-approvals'),
    management: path(ROOTS_DASHBOARD, '/leaves/management'),
    // records: path(ROOTS_DASHBOARD, '/leaves/records'),
    holidays: path(ROOTS_DASHBOARD, '/leaves/holidays'),
    schedule: path(ROOTS_DASHBOARD, '/leaves/schedule'),
    review: path(ROOTS_DASHBOARD, '/leaves/review'),
    transactions: path(ROOTS_DASHBOARD, '/leaves/transactions'),
    entitlement: path(ROOTS_DASHBOARD, '/leaves/entitlement'),
    types: path(ROOTS_DASHBOARD, '/leaves/types'),
    workDays: path(ROOTS_DASHBOARD, '/leaves/workdays'),
    earningPolicy: path(ROOTS_DASHBOARD, '/leaves/earning-policy'),
    approvalWorkflow: path(ROOTS_DASHBOARD, '/leaves/approval-workflow')
  },
  payroll: {
    // root: path(ROOTS_DASHBOARD, '/payroll/all'),
    salaryAdjustment: path(ROOTS_DASHBOARD, '/payroll/salary-adjustment')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

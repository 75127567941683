import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled, alpha } from '@material-ui/core/styles';
import { Box, Input, Slide, Button, InputAdornment, ClickAwayListener } from '@material-ui/core';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 36;
const APPBAR_DESKTOP = 36;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  paddingBottom: 2,
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    width: 'calc(100% + 24px)',
    height: APPBAR_DESKTOP + 1,
    paddingLeft: theme.spacing(6),
    marginLeft: theme.spacing(-3)
  },
  [theme.breakpoints.down('md')]: {
    height: APPBAR_DESKTOP + 1
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar(props) {
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setQuery('');
    setOpen(false);
  };

  const onSubmit = () => {
    props.onSearch(query);
    setOpen(false);
  };

  const onInputChange = (event) => {
    setQuery(event.target.value);
  };

  const projects = pathname.includes('/projects');
  const tasks = pathname.includes('/tasks');
  const team = pathname.includes('/team');

  const renderPlaceholder = () => {
    if (projects) return ' Search projects...';
    if (tasks) return ' Search tasks...';
    if (team) return ' Search team...';
    return '';
  };

  const renderSearch = () => (
    <>
      <MIconButton onClick={handleOpen} sx={{ color: 'white' }}>
        <Icon icon={searchFill} width={20} height={20} />
      </MIconButton>
      <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
        <SearchbarStyle>
          <Input
            autoFocus
            fullWidth
            disableUnderline
            placeholder={renderPlaceholder()}
            onChange={onInputChange}
            // value={query}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
          />
          <Button variant="contained" onClick={onSubmit} size="small">
            Search
          </Button>
        </SearchbarStyle>
      </Slide>
    </>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>{projects || tasks || team ? renderSearch() : ''}</div>
    </ClickAwayListener>
  );
}

import * as Yup from 'yup';
import { useState } from 'react';
// import { useSnackbar } from 'notistack5';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import closeFill from '@iconify/icons-eva/close-fill';
// material
import { Link, Stack, Alert, Checkbox, IconButton, InputAdornment, FormControlLabel } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import MuiLoadingButton from '@material-ui/lab/LoadingButton';
import { withStyles } from '@material-ui/styles';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import Spinner from '../../Spinner';
// import { MIconButton } from '../../@material-extend';
// import { isValidToken, setSession } from '../../../utils/jwt';
// import { postLogin } from '../../../server/server';
// config
import { authSchema } from '../../../utils/schemaConfig';

// ----------------------------------------------------------------------

const LoadingButton = withStyles({
  root: {
    maxWidth: 'auto'
  }
})(MuiLoadingButton);

const TextField = withStyles({})(MuiTextField);

export default function LoginForm() {
  const { login } = useAuth();
  // const isMountedRef = useIsMountedRef();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(authSchema.emailValid).strict().required(authSchema.email),
    password: Yup.string().required(authSchema.password)
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, setStatus }) => {
      // try {
      setLoading(true);
      setSubmitting(true);
      await login(values.email, values.password, { setErrors, setSubmitting, resetForm, setStatus });

      // if (isMountedRef.current) {
      //   setSubmitting(false);
      // }
      // }
      setLoading(false);
      //   catch (error) {
      //     console.error(error);
      //     resetForm();
      //     if (isMountedRef.current) {
      //       setSubmitting(false);
      //       setErrors({ afterSubmit: error.message });
      //     }
      //   }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      {!loading ? (
        <>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                // label="Email address"
                placeholder="Email address"
                disabled={isSubmitting}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={() => setFieldValue('email', values.email.toLowerCase().trim())}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                // label="Password"
                placeholder="Password"
                disabled={isSubmitting}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="Remember me"
              />

              <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Login
            </LoadingButton>
          </Form>
        </>
      ) : (
        <Spinner />
      )}
    </FormikProvider>
  );
}

// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import { getRoleId } from '../../utilities/utilities';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  mailOpen: getIcon('mail-open-svgrepo-com'),
  role: getIcon('user-role-svgrepo-com'),
  permission: getIcon('permission-svgrepo-com'),
  userManagement: getIcon('user-group-svgrepo-com'),
  dashboard2: getIcon('dashboard-2-svgrepo-com'),
  mail2: getIcon('email-svgrepo-com'),
  companies: getIcon('company-group-agency-institution-svgrepo-com'),
  owners: getIcon('business-man-svgrepo-com'),
  general: getIcon('setting-2-svgrepo-com')
};

// !getCompanyId()
// getCompanyId() && getProjectExist() === '1'
// getCompanyId() && getProjectExist() === '0'

const sidebarConfig = () =>
  getRoleId() === 10
    ? [
        {
          items: [
            // Super Admin
            {
              title: 'admin',
              path: PATH_ADMIN.superAdmin.admin,
              icon: ICONS.dashboard2
            },
            {
              title: 'email configurations',
              path: PATH_ADMIN.emailConfigurations.email,
              icon: ICONS.mail2
            },
            {
              title: 'email templates',
              path: PATH_ADMIN.emailTemplates.templates,
              icon: ICONS.mailOpen
            },
            {
              title: 'roles',
              path: PATH_ADMIN.roleManagement.roles,
              icon: ICONS.role
            },
            // {
            //   title: 'permissions',
            //   path: PATH_ADMIN.permissionManagement.permissions,
            //   icon: ICONS.permission
            // },
            {
              title: 'user management',
              path: PATH_ADMIN.userManagement.users,
              icon: ICONS.userManagement
            },
            {
              title: 'Companies',
              path: PATH_ADMIN.companyManagement.companies,
              icon: ICONS.companies
            },
            {
              title: 'Owners',
              path: PATH_ADMIN.ownerManagement.owners,
              icon: ICONS.owners
            }
            // {
            //   title: 'General Configuration',
            //   path: PATH_ADMIN.generalSettingsManagement.general,
            //   icon: ICONS.general
            // }
          ]
        }
      ]
    : [
        {
          items: [
            {
              title: 'dashboard',
              path: PATH_DASHBOARD.general.app,
              icon: ICONS.dashboard
            },

            {
              title: 'attendance',
              path: PATH_DASHBOARD.attendance.root,
              icon: ICONS.calendar,
              children: [1, 3, 8].includes(Number(getRoleId()))
                ? [
                    { title: 'updates', path: PATH_DASHBOARD.attendance.root },
                    { title: 'office timings', path: PATH_DASHBOARD.attendance.timing },
                    { title: 'log', path: PATH_DASHBOARD.attendance.log }
                  ]
                : [
                    { title: 'updates', path: PATH_DASHBOARD.attendance.root },
                    { title: 'office timings', path: PATH_DASHBOARD.attendance.timing }
                  ]
            },

            {
              title: 'company',
              path: PATH_DASHBOARD.company.root,
              icon: ICONS.banking
            },

            {
              title: 'projects',
              path: PATH_DASHBOARD.projects.root,
              icon: ICONS.analytics
            },

            {
              title: 'tasks',
              path: PATH_DASHBOARD.tasks.root,
              icon: ICONS.kanban,
              children: [
                { title: 'List', path: PATH_DASHBOARD.tasks.root },
                { title: 'Kanban', path: PATH_DASHBOARD.tasks.board }
              ]
            },

            // {
            //   title: 'timesheet',
            //   path: PATH_DASHBOARD.timesheet.root,
            //   icon: ICONS.booking
            // },

            {
              title: 'timesheet',
              path: PATH_DASHBOARD.timesheet.root,
              icon: ICONS.booking,
              children: [1, 3, 8].includes(Number(getRoleId()))
                ? [
                    { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
                    { title: 'List View', path: PATH_DASHBOARD.timesheet.list },
                    { title: 'Missing Timesheet', path: PATH_DASHBOARD.timesheet.missingList },
                    { title: 'Calendar view', path: PATH_DASHBOARD.timesheet.calendar }
                  ]
                : [
                    { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
                    { title: 'List View', path: PATH_DASHBOARD.timesheet.list }
                  ]
            },
            {
              title: 'leaves',
              path: PATH_DASHBOARD.leaves.root,
              icon: ICONS.calendar,
              children: [1, 3, 8].includes(Number(getRoleId()))
                ? [
                    { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
                    { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
                    { title: 'management', path: PATH_DASHBOARD.leaves.management },
                    { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
                    { title: 'leave type', path: PATH_DASHBOARD.leaves.types },
                    { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
                    { title: 'workdays', path: PATH_DASHBOARD.leaves.workDays },
                    { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule },
                    { title: 'earning policy', path: PATH_DASHBOARD.leaves.earningPolicy },
                    { title: 'approval workflow', path: PATH_DASHBOARD.leaves.approvalWorkflow }
                    // { title: 'records', path: PATH_DASHBOARD.leaves.records },
                    // { title: 'review', path: PATH_DASHBOARD.leaves.review },
                    // { title: 'transaction report', path: PATH_DASHBOARD.leaves.transactions },
                    // { title: 'entitlement report', path: PATH_DASHBOARD.leaves.entitlement },
                  ]
                : [
                    { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
                    { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
                    { title: 'management', path: PATH_DASHBOARD.leaves.management },
                    { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
                    { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
                    { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule }
                    // { title: 'workdays', path: PATH_DASHBOARD.leaves.workDays },
                    // { title: 'leave type', path: PATH_DASHBOARD.leaves.types },
                    // { title: 'earning policy', path: PATH_DASHBOARD.leaves.earningPolicy },
                    // { title: 'approval workflow', path: PATH_DASHBOARD.leaves.approvalWorkflow }
                    // { title: 'records', path: PATH_DASHBOARD.leaves.records },
                    // { title: 'review', path: PATH_DASHBOARD.leaves.review },
                    // { title: 'transaction report', path: PATH_DASHBOARD.leaves.transactions },
                    // { title: 'entitlement report', path: PATH_DASHBOARD.leaves.entitlement },
                  ]
            },
            {
              title: 'payroll',
              path: PATH_DASHBOARD.payroll.salaryAdjustment,
              icon: ICONS.banking,
              children: [
                {
                  title: [1, 3, 8].includes(Number(getRoleId())) ? 'salary adjustment' : 'salary',
                  path: PATH_DASHBOARD.payroll.salaryAdjustment
                }
              ]
            },
            {
              title: 'team',
              path: PATH_DASHBOARD.team.root,
              icon: ICONS.chat
            },
            {
              title: 'user',
              path: PATH_DASHBOARD.user.view,
              icon: ICONS.user
            }
          ]
        }
      ];

export default sidebarConfig;

// ----------------------------------------------------------------------

export default function Toolbar(theme) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          maxHeight: 40
        }
      }
    }
  };
}
